// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined'

export default {
  DashboardOutlined,
SettingOutlined,
UserOutlined
}
    