import { Settings as LayoutSettings } from '@ant-design/pro-layout';

export default {
  primaryColor: '#1890ff',
  layout: 'top',
  navTheme: 'dark',
  logo: '/xuanwu.png',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  menu: {
    locale: true,
  },
  title: 'XuanWu2000',
  splitMenus: false,
  pwa: false,
  iconfontUrl: '',
} as LayoutSettings & {
  pwa: boolean;
};
