import { useModel } from "umi";
import { getPageQuery } from '@/utils/utils';
import { history } from 'umi';
import { stringify } from 'querystring';
import { SessionControllerApi, Configuration } from "@/services/api";
import useBasePath from "./useBasePath";

export default function useCurrentUser() {
  const { initialState, setInitialState } = useModel('@@initialState');
  // console.log(process.env.NODE_ENV); //development | production
  const config = new Configuration({ 
    apiKey: initialState?.currentUser?.sessionToken,
    basePath: useBasePath(),
  });

  const logout = async (deleteSession: boolean) => {
    if (deleteSession) {
      const sessionApi = new SessionControllerApi(config);
      try {
        await sessionApi.sessionControllerDeleteSession();
      } catch(err) {
        console.log('deleteSession', err);
        // 不管有没有遇到错误，也继续在本地完成logout
      }
    }
  
    setInitialState({ ...initialState, currentUser: undefined });
    if (navigator.userAgent === 'xuanwu_operator') {
      window.localStorage.clear();
    } else {
      window.sessionStorage.clear();
    }

    const { redirect } = getPageQuery();
    // Note: There may be security issues, please note
    if (window.location.pathname !== '/user/login' && !redirect) {
      history.replace({
        pathname: '/user/login',
        search: deleteSession ? ''
          : stringify({redirect: window.location.href}),
      });
    }
  };

  return {
    currentUser: initialState?.currentUser,
    config,
    logout,
  };
}