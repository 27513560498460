/* tslint:disable */
/* eslint-disable */
/**
 * xuanwu-api
 * XuanWu API server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BindDeviceReply
 */
export interface BindDeviceReply {
    /**
     * 
     * @type {string}
     * @memberof BindDeviceReply
     */
    cityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BindDeviceReply
     */
    timeZoneOffset?: string;
    /**
     * 
     * @type {string}
     * @memberof BindDeviceReply
     */
    mqttUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BindDeviceReply
     */
    mqttUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof BindDeviceReply
     */
    mqttPassword?: string;
}
/**
 * 
 * @export
 * @interface BindDeviceRequest
 */
export interface BindDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof BindDeviceRequest
     */
    device_sn: string;
    /**
     * 
     * @type {number}
     * @memberof BindDeviceRequest
     */
    space_node_id: number | null;
    /**
     * 
     * @type {Geolocation}
     * @memberof BindDeviceRequest
     */
    geolocation?: Geolocation;
}
/**
 * 
 * @export
 * @interface Captcha
 */
export interface Captcha {
    /**
     * 
     * @type {string}
     * @memberof Captcha
     */
    captchaId: string;
    /**
     * 
     * @type {string}
     * @memberof Captcha
     */
    svgData: string;
}
/**
 * 
 * @export
 * @interface ColorLightRgb
 */
export interface ColorLightRgb {
    /**
     * 
     * @type {number}
     * @memberof ColorLightRgb
     */
    r?: number;
    /**
     * 
     * @type {number}
     * @memberof ColorLightRgb
     */
    g?: number;
    /**
     * 
     * @type {number}
     * @memberof ColorLightRgb
     */
    b?: number;
}
/**
 * 
 * @export
 * @interface CommandX1000
 */
export interface CommandX1000 {
    /**
     * 
     * @type {number}
     * @memberof CommandX1000
     */
    restoreAfterHours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    powerOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    lockOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    uvOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    anionOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    ledOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX1000
     */
    beepOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommandX1000
     */
    modeAndShift?: CommandX1000ModeAndShiftEnum;
    /**
     * 
     * @type {number}
     * @memberof CommandX1000
     */
    colorLightMode?: CommandX1000ColorLightModeEnum;
    /**
     * 
     * @type {ColorLightRgb}
     * @memberof CommandX1000
     */
    colorLightRgb?: ColorLightRgb;
    /**
     * 
     * @type {string}
     * @memberof CommandX1000
     */
    rulesetJson?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommandX1000ModeAndShiftEnum {
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8
}
/**
    * @export
    * @enum {string}
    */
export enum CommandX1000ColorLightModeEnum {
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7
}

/**
 * 
 * @export
 * @interface CommandX400
 */
export interface CommandX400 {
    /**
     * 
     * @type {number}
     * @memberof CommandX400
     */
    restoreAfterHours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    powerOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    lockOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    uvOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    anionOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    ledOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommandX400
     */
    beepOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommandX400
     */
    modeAndShift?: CommandX400ModeAndShiftEnum;
    /**
     * 
     * @type {string}
     * @memberof CommandX400
     */
    rulesetJson?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CommandX400ModeAndShiftEnum {
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_null = null,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface DescendentSpace
 */
export interface DescendentSpace {
    /**
     * 
     * @type {Array<object>}
     * @memberof DescendentSpace
     */
    spaceNodes?: Array<object>;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    device_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    device_sn: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    geolocation?: string;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    owner_node_id?: number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    located_node_id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    apply_device_scene?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Device
     */
    device_scene_id?: object;
}
/**
 * 
 * @export
 * @interface DeviceAlert
 */
export interface DeviceAlert {
    /**
     * 
     * @type {string}
     * @memberof DeviceAlert
     */
    deviceSn?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAlert
     */
    alertType?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAlert
     */
    alertTime?: string;
}
/**
 * 
 * @export
 * @interface DeviceCommandConfirm
 */
export interface DeviceCommandConfirm {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceCommandConfirm
     */
    hasConfirm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceCommandConfirm
     */
    lastCmdJson?: string;
}
/**
 * 
 * @export
 * @interface DeviceLocation
 */
export interface DeviceLocation {
    /**
     * 
     * @type {string}
     * @memberof DeviceLocation
     */
    deviceSn?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLocation
     */
    label?: string;
    /**
     * 
     * @type {Geolocation}
     * @memberof DeviceLocation
     */
    geoLocation?: Geolocation;
}
/**
 * 
 * @export
 * @interface DeviceScene
 */
export interface DeviceScene {
    /**
     * 
     * @type {number}
     * @memberof DeviceScene
     */
    device_scene_id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceScene
     */
    device_scene_name: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceScene
     */
    device_type_short: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceScene
     */
    iso_country_code: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceScene
     */
    node_id?: number;
}
/**
 * 
 * @export
 * @interface DeviceSchedule
 */
export interface DeviceSchedule {
    /**
     * 
     * @type {number}
     * @memberof DeviceSchedule
     */
    device_schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceSchedule
     */
    device_schedule_time: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceSchedule
     */
    device_command_json?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceSchedule
     */
    device_ruleset_json?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_workday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_holiday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_sunday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_monday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_tuesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_wednesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_thursday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_friday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSchedule
     */
    apply_on_saturday?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceSchedule
     */
    device_scene_id?: number;
}
/**
 * 
 * @export
 * @interface Geolocation
 */
export interface Geolocation {
    /**
     * 
     * @type {LatLng}
     * @memberof Geolocation
     */
    WGS84?: LatLng;
    /**
     * 
     * @type {LatLng}
     * @memberof Geolocation
     */
    BD09?: LatLng;
}
/**
 * 
 * @export
 * @interface LatLng
 */
export interface LatLng {
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    lng?: number;
}
/**
 * 
 * @export
 * @interface LoginReply
 */
export interface LoginReply {
    /**
     * 
     * @type {string}
     * @memberof LoginReply
     */
    sessionToken: string;
    /**
     * 
     * @type {number}
     * @memberof LoginReply
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginReply
     */
    user_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginReply
     */
    isSuperRoot?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoginReply
     */
    accessRoot?: number;
    /**
     * 
     * @type {Array<PermissionItem>}
     * @memberof LoginReply
     */
    permissions?: Array<PermissionItem>;
}
/**
 * 
 * @export
 * @interface LoginWithPasswordRequest
 */
export interface LoginWithPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginWithPasswordRequest
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPasswordRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPasswordRequest
     */
    captchaId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithPasswordRequest
     */
    captchaText: string;
}
/**
 * 
 * @export
 * @interface LoopbackCount
 */
export interface LoopbackCount {
    /**
     * 
     * @type {number}
     * @memberof LoopbackCount
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface ModifyPasswordRequest
 */
export interface ModifyPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifyPasswordRequest
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyPasswordRequest
     */
    newPassword: string;
}
/**
 * (tsType: Omit<DeviceScene, \'device_scene_id\' | \'node_id\'>, schemaOptions: { title: \'NewDeviceScene\', exclude: [ \'device_scene_id\', \'node_id\' ] })
 * @export
 * @interface NewDeviceScene
 */
export interface NewDeviceScene {
    /**
     * 
     * @type {string}
     * @memberof NewDeviceScene
     */
    device_scene_name: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeviceScene
     */
    device_type_short: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeviceScene
     */
    iso_country_code: string;
}
/**
 * (tsType: Omit<DeviceSchedule, \'device_schedule_id\' | \'device_scene_id\'>, schemaOptions: { title: \'NewDeviceSchedule\', exclude: [ \'device_schedule_id\', \'device_scene_id\' ] })
 * @export
 * @interface NewDeviceSchedule
 */
export interface NewDeviceSchedule {
    /**
     * 
     * @type {string}
     * @memberof NewDeviceSchedule
     */
    device_schedule_time: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeviceSchedule
     */
    device_command_json?: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeviceSchedule
     */
    device_ruleset_json?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_workday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_holiday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_sunday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_monday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_tuesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_wednesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_thursday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_friday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewDeviceSchedule
     */
    apply_on_saturday?: boolean;
}
/**
 * (tsType: Omit<Node, \'node_id\' | \'parent_node_id\' | \'ancestors\' | \'node_type\'>, schemaOptions: { title: \'NewNodeInNode\', exclude: [ \'node_id\', \'parent_node_id\', \'ancestors\', \'node_type\' ] })
 * @export
 * @interface NewNodeInNode
 */
export interface NewNodeInNode {
    /**
     * 
     * @type {string}
     * @memberof NewNodeInNode
     */
    node_name: string;
    /**
     * 
     * @type {string}
     * @memberof NewNodeInNode
     */
    geolocation?: string;
}
/**
 * (tsType: Omit<Permission, \'permission_id\' | \'role_id\'>, schemaOptions: { title: \'NewPermissionInRole\', exclude: [ \'permission_id\', \'role_id\' ] })
 * @export
 * @interface NewPermissionInRole
 */
export interface NewPermissionInRole {
    /**
     * 
     * @type {string}
     * @memberof NewPermissionInRole
     */
    operations: string;
    /**
     * 
     * @type {number}
     * @memberof NewPermissionInRole
     */
    access_node_id?: number;
}
/**
 * (tsType: Omit<Role, \'role_id\' | \'node_id\'>, schemaOptions: { title: \'NewRoleInNode\', exclude: [ \'role_id\', \'node_id\' ] })
 * @export
 * @interface NewRoleInNode
 */
export interface NewRoleInNode {
    /**
     * 
     * @type {string}
     * @memberof NewRoleInNode
     */
    role_name: string;
}
/**
 * (tsType: Omit<TvClient, \'tv_client_id\' | \'node_id\'>, schemaOptions: { title: \'NewTvClient\', exclude: [ \'tv_client_id\', \'node_id\' ] })
 * @export
 * @interface NewTvClient
 */
export interface NewTvClient {
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    tv_client_uid: string;
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    tv_client_name: string;
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    display_type: NewTvClientDisplayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    display_item_id: string;
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    display_style: NewTvClientDisplayStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof NewTvClient
     */
    display_locale: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewTvClientDisplayTypeEnum {
    Node = 'Node',
    Device = 'Device'
}
/**
    * @export
    * @enum {string}
    */
export enum NewTvClientDisplayStyleEnum {
    FullScreen = 'FullScreen',
    Top = 'Top',
    Bottom = 'Bottom',
    DebugFullScreen = 'DebugFullScreen',
    DebugTop = 'DebugTop',
    DebugBottom = 'DebugBottom'
}

/**
 * (tsType: UserRequest, schemaOptions: { title: \'NewUser\' })
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    password: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewUser
     */
    userRoleIds?: Array<number>;
}
/**
 * (tsType: Omit<UserRole, \'user_role_id\' | \'user_id\'>, schemaOptions: { title: \'NewUserRoleInUser\', exclude: [ \'user_role_id\', \'user_id\' ] })
 * @export
 * @interface NewUserRoleInUser
 */
export interface NewUserRoleInUser {
    /**
     * 
     * @type {number}
     * @memberof NewUserRoleInUser
     */
    role_id?: number;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    node_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    ancestors?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    node_name: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    node_type: NodeNodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    geolocation?: string;
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    parent_node_id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum NodeNodeTypeEnum {
    Organization = 'Organization',
    Space = 'Space',
    Category = 'Category'
}

/**
 * 
 * @export
 * @interface NodeDevice
 */
export interface NodeDevice {
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    deviceSn?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    spacePath?: string;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    underOrgNodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    categories?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    deviceTypeShort?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    modeAndShift?: string;
    /**
     * 
     * @type {Array<DeviceAlert>}
     * @memberof NodeDevice
     */
    alert?: Array<DeviceAlert>;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    filterLife?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    pm25?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeDevice
     */
    sceneName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NodeDevice
     */
    applyScene?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    sceneInterruptWaitSeconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NodeDevice
     */
    lockOn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    colorLightMode?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeDevice
     */
    manualInterruptRestoreAfterHours?: number;
}
/**
 * 
 * @export
 * @interface NodeDevices
 */
export interface NodeDevices {
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    statsDeviceType?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    alerts?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    statsModeAndShift?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    statsScene?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    statsX1000FilterLife?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeDevices
     */
    statsX400FilterLife?: object;
    /**
     * 
     * @type {Array<NodeDevice>}
     * @memberof NodeDevices
     */
    deviceList?: Array<NodeDevice>;
}
/**
 * 
 * @export
 * @interface NodeInfo
 */
export interface NodeInfo {
    /**
     * 
     * @type {number}
     * @memberof NodeInfo
     */
    node_id?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    ancestors?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    node_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    node_type?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    geolocation?: string;
    /**
     * 
     * @type {number}
     * @memberof NodeInfo
     */
    parent_node_id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeInfo
     */
    node_path?: Array<string>;
}
/**
 * 
 * @export
 * @interface NodeSummary
 */
export interface NodeSummary {
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    totalWorkTime?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    totalPurify?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    totalPower?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    avgPurify?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    avgPower?: number;
    /**
     * 
     * @type {number}
     * @memberof NodeSummary
     */
    pm25?: number;
}
/**
 * 
 * @export
 * @interface NodesAndLocatedDevices
 */
export interface NodesAndLocatedDevices {
    /**
     * 
     * @type {Array<Node>}
     * @memberof NodesAndLocatedDevices
     */
    nodes?: Array<Node>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof NodesAndLocatedDevices
     */
    locatedDevices?: Array<Device>;
}
/**
 * (tsType: Omit<Node, \'node_id\' | \'parent_node_id\' | \'ancestors\' | \'node_type\'>, schemaOptions: { title: \'PatchCateNode\', exclude: [ \'node_id\', \'parent_node_id\', \'ancestors\', \'node_type\' ] })
 * @export
 * @interface PatchCateNode
 */
export interface PatchCateNode {
    /**
     * 
     * @type {string}
     * @memberof PatchCateNode
     */
    node_name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCateNode
     */
    geolocation?: string;
}
/**
 * (tsType: Omit<Partial<DeviceScene>, \'node_id\' | \'device_scene_id\' | \'device_type_short\'>, schemaOptions: { partial: true, title: \'PatchDeviceScene\', exclude: [ \'node_id\', \'device_scene_id\', \'device_type_short\' ] })
 * @export
 * @interface PatchDeviceScene
 */
export interface PatchDeviceScene {
    /**
     * 
     * @type {string}
     * @memberof PatchDeviceScene
     */
    device_scene_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDeviceScene
     */
    iso_country_code?: string;
}
/**
 * (tsType: Omit<Partial<DeviceSchedule>, \'device_scene_id\' | \'device_schedule_id\'>, schemaOptions: { partial: true, title: \'PatchDeviceSchedule\', exclude: [ \'device_scene_id\', \'device_schedule_id\' ] })
 * @export
 * @interface PatchDeviceSchedule
 */
export interface PatchDeviceSchedule {
    /**
     * 
     * @type {string}
     * @memberof PatchDeviceSchedule
     */
    device_schedule_time?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDeviceSchedule
     */
    device_command_json?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDeviceSchedule
     */
    device_ruleset_json?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_workday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_holiday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_sunday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_monday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_tuesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_wednesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_thursday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_friday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDeviceSchedule
     */
    apply_on_saturday?: boolean;
}
/**
 * (tsType: Omit<Node, \'node_id\' | \'parent_node_id\' | \'ancestors\' | \'node_type\'>, schemaOptions: { title: \'PatchOrgNode\', exclude: [ \'node_id\', \'parent_node_id\', \'ancestors\', \'node_type\' ] })
 * @export
 * @interface PatchOrgNode
 */
export interface PatchOrgNode {
    /**
     * 
     * @type {string}
     * @memberof PatchOrgNode
     */
    node_name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchOrgNode
     */
    geolocation?: string;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<Permission, \'permission_id\' | \'role_id\'>, \'access_node_id\'>, schemaOptions: { title: \'PatchPermission\', exclude: [ \'permission_id\', \'role_id\' ], optional: [ \'access_node_id\' ] })
 * @export
 * @interface PatchPermission
 */
export interface PatchPermission {
    /**
     * 
     * @type {string}
     * @memberof PatchPermission
     */
    operations: string;
    /**
     * 
     * @type {number}
     * @memberof PatchPermission
     */
    access_node_id?: number;
}
/**
 * (tsType: Omit<Role, \'node_id\' | \'role_id\'>, schemaOptions: { title: \'PatchRole\', exclude: [ \'node_id\', \'role_id\' ] })
 * @export
 * @interface PatchRole
 */
export interface PatchRole {
    /**
     * 
     * @type {string}
     * @memberof PatchRole
     */
    role_name: string;
}
/**
 * (tsType: Omit<Partial<TvClient>, \'node_id\' | \'tv_client_id\' | \'tv_client_uid\'>, schemaOptions: { partial: true, title: \'PatchTvClient\', exclude: [ \'node_id\', \'tv_client_id\', \'tv_client_uid\' ] })
 * @export
 * @interface PatchTvClient
 */
export interface PatchTvClient {
    /**
     * 
     * @type {string}
     * @memberof PatchTvClient
     */
    tv_client_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTvClient
     */
    display_type?: PatchTvClientDisplayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchTvClient
     */
    display_item_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTvClient
     */
    display_style?: PatchTvClientDisplayStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchTvClient
     */
    display_locale?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PatchTvClientDisplayTypeEnum {
    Node = 'Node',
    Device = 'Device'
}
/**
    * @export
    * @enum {string}
    */
export enum PatchTvClientDisplayStyleEnum {
    FullScreen = 'FullScreen',
    Top = 'Top',
    Bottom = 'Bottom',
    DebugFullScreen = 'DebugFullScreen',
    DebugTop = 'DebugTop',
    DebugBottom = 'DebugBottom'
}

/**
 * (tsType: @loopback/repository-json-schema#Optional<UserRequest, \'password\' | \'user_name\'>, schemaOptions: { title: \'PatchUser\', optional: [ \'password\', \'user_name\' ] })
 * @export
 * @interface PatchUser
 */
export interface PatchUser {
    /**
     * 
     * @type {string}
     * @memberof PatchUser
     */
    user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUser
     */
    password?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchUser
     */
    userRoleIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    permission_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    operations: string;
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    access_node_id?: number;
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    role_id?: number;
}
/**
 * 
 * @export
 * @interface PermissionItem
 */
export interface PermissionItem {
    /**
     * 
     * @type {string}
     * @memberof PermissionItem
     */
    operations: string;
    /**
     * 
     * @type {number}
     * @memberof PermissionItem
     */
    access_node_id: number;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    role_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    role_name: string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    node_id?: number;
}
/**
 * 
 * @export
 * @interface SetDeviceSceneRequest
 */
export interface SetDeviceSceneRequest {
    /**
     * 
     * @type {number}
     * @memberof SetDeviceSceneRequest
     */
    device_scene_id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetDeviceSceneRequest
     */
    apply_device_scene?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SetDeviceSceneRequest
     */
    manualInterruptRestoreAfterHours?: number | null;
}
/**
 * (tsType: SpaceNode, schemaOptions: { title: \'SpaceNode\' })
 * @export
 * @interface SpaceNode
 */
export interface SpaceNode {
    /**
     * 
     * @type {string}
     * @memberof SpaceNode
     */
    node_name: string;
    /**
     * 
     * @type {string}
     * @memberof SpaceNode
     */
    geolocation?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SpaceNode
     */
    spaceCateIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface TvClient
 */
export interface TvClient {
    /**
     * 
     * @type {number}
     * @memberof TvClient
     */
    tv_client_id?: number;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    tv_client_uid: string;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    tv_client_name: string;
    /**
     * 
     * @type {number}
     * @memberof TvClient
     */
    node_id?: number;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    display_type: TvClientDisplayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    display_item_id: string;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    display_style: TvClientDisplayStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof TvClient
     */
    display_locale: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TvClientDisplayTypeEnum {
    Node = 'Node',
    Device = 'Device'
}
/**
    * @export
    * @enum {string}
    */
export enum TvClientDisplayStyleEnum {
    FullScreen = 'FullScreen',
    Top = 'Top',
    Bottom = 'Bottom',
    DebugFullScreen = 'DebugFullScreen',
    DebugTop = 'DebugTop',
    DebugBottom = 'DebugBottom'
}

/**
 * 
 * @export
 * @interface TvClientDisplay
 */
export interface TvClientDisplay {
    /**
     * 
     * @type {string}
     * @memberof TvClientDisplay
     */
    displayPath?: string;
    /**
     * 
     * @type {string}
     * @memberof TvClientDisplay
     */
    displayType?: string;
    /**
     * 
     * @type {string}
     * @memberof TvClientDisplay
     */
    displayStyle?: string;
    /**
     * 
     * @type {string}
     * @memberof TvClientDisplay
     */
    deviceTypeShort?: string;
    /**
     * 
     * @type {string}
     * @memberof TvClientDisplay
     */
    displayLocale?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    node_id?: number;
}
/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    password: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserRequest
     */
    userRoleIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    user_role_id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    role_id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    user_id?: number;
}

/**
 * CateNodeControllerApi - axios parameter creator
 * @export
 */
export const CateNodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建分类结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerCreateCateNode: async (nodeId: number, newNodeInNode?: NewNodeInNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling cateNodeControllerCreateCateNode.');
            }
            const localVarPath = `/nodes/{node_id}/cate-nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newNodeInNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newNodeInNode !== undefined ? newNodeInNode : {}) : (newNodeInNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerDeleteCateNode: async (nodeId: number, cateNodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling cateNodeControllerDeleteCateNode.');
            }
            // verify required parameter 'cateNodeId' is not null or undefined
            if (cateNodeId === null || cateNodeId === undefined) {
                throw new RequiredError('cateNodeId','Required parameter cateNodeId was null or undefined when calling cateNodeControllerDeleteCateNode.');
            }
            const localVarPath = `/nodes/{node_id}/cate-nodes/{cate_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"cate_node_id"}}`, encodeURIComponent(String(cateNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerGetCategories: async (orgNodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling cateNodeControllerGetCategories.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/categories`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {PatchCateNode} [patchCateNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerPatchCateNode: async (nodeId: number, cateNodeId: number, patchCateNode?: PatchCateNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling cateNodeControllerPatchCateNode.');
            }
            // verify required parameter 'cateNodeId' is not null or undefined
            if (cateNodeId === null || cateNodeId === undefined) {
                throw new RequiredError('cateNodeId','Required parameter cateNodeId was null or undefined when calling cateNodeControllerPatchCateNode.');
            }
            const localVarPath = `/nodes/{node_id}/cate-nodes/{cate_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"cate_node_id"}}`, encodeURIComponent(String(cateNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchCateNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchCateNode !== undefined ? patchCateNode : {}) : (patchCateNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CateNodeControllerApi - functional programming interface
 * @export
 */
export const CateNodeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建分类结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cateNodeControllerCreateCateNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Node>> {
            const localVarAxiosArgs = await CateNodeControllerApiAxiosParamCreator(configuration).cateNodeControllerCreateCateNode(nodeId, newNodeInNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cateNodeControllerDeleteCateNode(nodeId: number, cateNodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CateNodeControllerApiAxiosParamCreator(configuration).cateNodeControllerDeleteCateNode(nodeId, cateNodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cateNodeControllerGetCategories(orgNodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await CateNodeControllerApiAxiosParamCreator(configuration).cateNodeControllerGetCategories(orgNodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {PatchCateNode} [patchCateNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cateNodeControllerPatchCateNode(nodeId: number, cateNodeId: number, patchCateNode?: PatchCateNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CateNodeControllerApiAxiosParamCreator(configuration).cateNodeControllerPatchCateNode(nodeId, cateNodeId, patchCateNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CateNodeControllerApi - factory interface
 * @export
 */
export const CateNodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 在结点下新建分类结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerCreateCateNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any): AxiosPromise<Node> {
            return CateNodeControllerApiFp(configuration).cateNodeControllerCreateCateNode(nodeId, newNodeInNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerDeleteCateNode(nodeId: number, cateNodeId: number, options?: any): AxiosPromise<void> {
            return CateNodeControllerApiFp(configuration).cateNodeControllerDeleteCateNode(nodeId, cateNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerGetCategories(orgNodeId: number, options?: any): AxiosPromise<Array<Node>> {
            return CateNodeControllerApiFp(configuration).cateNodeControllerGetCategories(orgNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} cateNodeId 
         * @param {PatchCateNode} [patchCateNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cateNodeControllerPatchCateNode(nodeId: number, cateNodeId: number, patchCateNode?: PatchCateNode, options?: any): AxiosPromise<void> {
            return CateNodeControllerApiFp(configuration).cateNodeControllerPatchCateNode(nodeId, cateNodeId, patchCateNode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CateNodeControllerApi - object-oriented interface
 * @export
 * @class CateNodeControllerApi
 * @extends {BaseAPI}
 */
export class CateNodeControllerApi extends BaseAPI {
    /**
     * 
     * @summary 在结点下新建分类结点。
     * @param {number} nodeId 
     * @param {NewNodeInNode} [newNodeInNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CateNodeControllerApi
     */
    public cateNodeControllerCreateCateNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any) {
        return CateNodeControllerApiFp(this.configuration).cateNodeControllerCreateCateNode(nodeId, newNodeInNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} cateNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CateNodeControllerApi
     */
    public cateNodeControllerDeleteCateNode(nodeId: number, cateNodeId: number, options?: any) {
        return CateNodeControllerApiFp(this.configuration).cateNodeControllerDeleteCateNode(nodeId, cateNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CateNodeControllerApi
     */
    public cateNodeControllerGetCategories(orgNodeId: number, options?: any) {
        return CateNodeControllerApiFp(this.configuration).cateNodeControllerGetCategories(orgNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} cateNodeId 
     * @param {PatchCateNode} [patchCateNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CateNodeControllerApi
     */
    public cateNodeControllerPatchCateNode(nodeId: number, cateNodeId: number, patchCateNode?: PatchCateNode, options?: any) {
        return CateNodeControllerApiFp(this.configuration).cateNodeControllerPatchCateNode(nodeId, cateNodeId, patchCateNode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceControllerApi - axios parameter creator
 * @export
 */
export const DeviceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCountDevices: async (orgNodeId: number, where?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceControllerCountDevices.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/devices/count`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCreateDevices: async (orgNodeId: number, requestBody?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceControllerCreateDevices.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/devices`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerDeleteDevice: async (orgNodeId: number, deviceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceControllerDeleteDevice.');
            }
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new RequiredError('deviceId','Required parameter deviceId was null or undefined when calling deviceControllerDeleteDevice.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/devices/{device_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerFindDevices: async (orgNodeId: number, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceControllerFindDevices.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/devices`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceControllerApi - functional programming interface
 * @export
 */
export const DeviceControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerCountDevices(orgNodeId: number, where?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await DeviceControllerApiAxiosParamCreator(configuration).deviceControllerCountDevices(orgNodeId, where, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerCreateDevices(orgNodeId: number, requestBody?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DeviceControllerApiAxiosParamCreator(configuration).deviceControllerCreateDevices(orgNodeId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerDeleteDevice(orgNodeId: number, deviceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceControllerApiAxiosParamCreator(configuration).deviceControllerDeleteDevice(orgNodeId, deviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerFindDevices(orgNodeId: number, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await DeviceControllerApiAxiosParamCreator(configuration).deviceControllerFindDevices(orgNodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeviceControllerApi - factory interface
 * @export
 */
export const DeviceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCountDevices(orgNodeId: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return DeviceControllerApiFp(configuration).deviceControllerCountDevices(orgNodeId, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCreateDevices(orgNodeId: number, requestBody?: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return DeviceControllerApiFp(configuration).deviceControllerCreateDevices(orgNodeId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerDeleteDevice(orgNodeId: number, deviceId: number, options?: any): AxiosPromise<void> {
            return DeviceControllerApiFp(configuration).deviceControllerDeleteDevice(orgNodeId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerFindDevices(orgNodeId: number, filter?: string, options?: any): AxiosPromise<Array<any>> {
            return DeviceControllerApiFp(configuration).deviceControllerFindDevices(orgNodeId, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceControllerApi - object-oriented interface
 * @export
 * @class DeviceControllerApi
 * @extends {BaseAPI}
 */
export class DeviceControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} orgNodeId 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControllerApi
     */
    public deviceControllerCountDevices(orgNodeId: number, where?: { [key: string]: object; }, options?: any) {
        return DeviceControllerApiFp(this.configuration).deviceControllerCountDevices(orgNodeId, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControllerApi
     */
    public deviceControllerCreateDevices(orgNodeId: number, requestBody?: Array<string>, options?: any) {
        return DeviceControllerApiFp(this.configuration).deviceControllerCreateDevices(orgNodeId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControllerApi
     */
    public deviceControllerDeleteDevice(orgNodeId: number, deviceId: number, options?: any) {
        return DeviceControllerApiFp(this.configuration).deviceControllerDeleteDevice(orgNodeId, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceControllerApi
     */
    public deviceControllerFindDevices(orgNodeId: number, filter?: string, options?: any) {
        return DeviceControllerApiFp(this.configuration).deviceControllerFindDevices(orgNodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceSceneControllerApi - axios parameter creator
 * @export
 */
export const DeviceSceneControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewDeviceScene} [newDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerCreateDeviceScene: async (orgNodeId: number, newDeviceScene?: NewDeviceScene, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerCreateDeviceScene.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDeviceScene !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDeviceScene !== undefined ? newDeviceScene : {}) : (newDeviceScene || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {NewDeviceSchedule} [newDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerCreateDeviceSchedule: async (orgNodeId: number, deviceSceneId: number, newDeviceSchedule?: NewDeviceSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerCreateDeviceSchedule.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerCreateDeviceSchedule.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}/schedules`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDeviceSchedule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDeviceSchedule !== undefined ? newDeviceSchedule : {}) : (newDeviceSchedule || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerDeleteDeviceScene: async (orgNodeId: number, deviceSceneId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerDeleteDeviceScene.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerDeleteDeviceScene.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerDeleteDeviceSchedule: async (orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerDeleteDeviceSchedule.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerDeleteDeviceSchedule.');
            }
            // verify required parameter 'deviceScheduleId' is not null or undefined
            if (deviceScheduleId === null || deviceScheduleId === undefined) {
                throw new RequiredError('deviceScheduleId','Required parameter deviceScheduleId was null or undefined when calling deviceSceneControllerDeleteDeviceSchedule.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}/schedules/{device_schedule_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)))
                .replace(`{${"device_schedule_id"}}`, encodeURIComponent(String(deviceScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerFindDeviceScene: async (orgNodeId: number, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerFindDeviceScene.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerFindDeviceSchedule: async (orgNodeId: number, deviceSceneId: number, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerFindDeviceSchedule.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerFindDeviceSchedule.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}/schedules`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {PatchDeviceScene} [patchDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerPatchDeviceScene: async (orgNodeId: number, deviceSceneId: number, patchDeviceScene?: PatchDeviceScene, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerPatchDeviceScene.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerPatchDeviceScene.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchDeviceScene !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchDeviceScene !== undefined ? patchDeviceScene : {}) : (patchDeviceScene || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {PatchDeviceSchedule} [patchDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerPatchDeviceSchedule: async (orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, patchDeviceSchedule?: PatchDeviceSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling deviceSceneControllerPatchDeviceSchedule.');
            }
            // verify required parameter 'deviceSceneId' is not null or undefined
            if (deviceSceneId === null || deviceSceneId === undefined) {
                throw new RequiredError('deviceSceneId','Required parameter deviceSceneId was null or undefined when calling deviceSceneControllerPatchDeviceSchedule.');
            }
            // verify required parameter 'deviceScheduleId' is not null or undefined
            if (deviceScheduleId === null || deviceScheduleId === undefined) {
                throw new RequiredError('deviceScheduleId','Required parameter deviceScheduleId was null or undefined when calling deviceSceneControllerPatchDeviceSchedule.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/device-scenes/{device_scene_id}/schedules/{device_schedule_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"device_scene_id"}}`, encodeURIComponent(String(deviceSceneId)))
                .replace(`{${"device_schedule_id"}}`, encodeURIComponent(String(deviceScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchDeviceSchedule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchDeviceSchedule !== undefined ? patchDeviceSchedule : {}) : (patchDeviceSchedule || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceSceneControllerApi - functional programming interface
 * @export
 */
export const DeviceSceneControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewDeviceScene} [newDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerCreateDeviceScene(orgNodeId: number, newDeviceScene?: NewDeviceScene, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceScene>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerCreateDeviceScene(orgNodeId, newDeviceScene, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {NewDeviceSchedule} [newDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerCreateDeviceSchedule(orgNodeId: number, deviceSceneId: number, newDeviceSchedule?: NewDeviceSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSchedule>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerCreateDeviceSchedule(orgNodeId, deviceSceneId, newDeviceSchedule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerDeleteDeviceScene(orgNodeId: number, deviceSceneId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerDeleteDeviceScene(orgNodeId, deviceSceneId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerDeleteDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerDeleteDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerFindDeviceScene(orgNodeId: number, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceScene>>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerFindDeviceScene(orgNodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerFindDeviceSchedule(orgNodeId: number, deviceSceneId: number, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceSchedule>>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerFindDeviceSchedule(orgNodeId, deviceSceneId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {PatchDeviceScene} [patchDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerPatchDeviceScene(orgNodeId: number, deviceSceneId: number, patchDeviceScene?: PatchDeviceScene, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerPatchDeviceScene(orgNodeId, deviceSceneId, patchDeviceScene, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {PatchDeviceSchedule} [patchDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSceneControllerPatchDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, patchDeviceSchedule?: PatchDeviceSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeviceSceneControllerApiAxiosParamCreator(configuration).deviceSceneControllerPatchDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, patchDeviceSchedule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeviceSceneControllerApi - factory interface
 * @export
 */
export const DeviceSceneControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewDeviceScene} [newDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerCreateDeviceScene(orgNodeId: number, newDeviceScene?: NewDeviceScene, options?: any): AxiosPromise<DeviceScene> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerCreateDeviceScene(orgNodeId, newDeviceScene, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {NewDeviceSchedule} [newDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerCreateDeviceSchedule(orgNodeId: number, deviceSceneId: number, newDeviceSchedule?: NewDeviceSchedule, options?: any): AxiosPromise<DeviceSchedule> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerCreateDeviceSchedule(orgNodeId, deviceSceneId, newDeviceSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerDeleteDeviceScene(orgNodeId: number, deviceSceneId: number, options?: any): AxiosPromise<void> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerDeleteDeviceScene(orgNodeId, deviceSceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerDeleteDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, options?: any): AxiosPromise<void> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerDeleteDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerFindDeviceScene(orgNodeId: number, filter?: string, options?: any): AxiosPromise<Array<DeviceScene>> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerFindDeviceScene(orgNodeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerFindDeviceSchedule(orgNodeId: number, deviceSceneId: number, filter?: string, options?: any): AxiosPromise<Array<DeviceSchedule>> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerFindDeviceSchedule(orgNodeId, deviceSceneId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {PatchDeviceScene} [patchDeviceScene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerPatchDeviceScene(orgNodeId: number, deviceSceneId: number, patchDeviceScene?: PatchDeviceScene, options?: any): AxiosPromise<void> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerPatchDeviceScene(orgNodeId, deviceSceneId, patchDeviceScene, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} deviceSceneId 
         * @param {number} deviceScheduleId 
         * @param {PatchDeviceSchedule} [patchDeviceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSceneControllerPatchDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, patchDeviceSchedule?: PatchDeviceSchedule, options?: any): AxiosPromise<void> {
            return DeviceSceneControllerApiFp(configuration).deviceSceneControllerPatchDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, patchDeviceSchedule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceSceneControllerApi - object-oriented interface
 * @export
 * @class DeviceSceneControllerApi
 * @extends {BaseAPI}
 */
export class DeviceSceneControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} orgNodeId 
     * @param {NewDeviceScene} [newDeviceScene] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerCreateDeviceScene(orgNodeId: number, newDeviceScene?: NewDeviceScene, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerCreateDeviceScene(orgNodeId, newDeviceScene, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {NewDeviceSchedule} [newDeviceSchedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerCreateDeviceSchedule(orgNodeId: number, deviceSceneId: number, newDeviceSchedule?: NewDeviceSchedule, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerCreateDeviceSchedule(orgNodeId, deviceSceneId, newDeviceSchedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerDeleteDeviceScene(orgNodeId: number, deviceSceneId: number, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerDeleteDeviceScene(orgNodeId, deviceSceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {number} deviceScheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerDeleteDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerDeleteDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerFindDeviceScene(orgNodeId: number, filter?: string, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerFindDeviceScene(orgNodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerFindDeviceSchedule(orgNodeId: number, deviceSceneId: number, filter?: string, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerFindDeviceSchedule(orgNodeId, deviceSceneId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {PatchDeviceScene} [patchDeviceScene] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerPatchDeviceScene(orgNodeId: number, deviceSceneId: number, patchDeviceScene?: PatchDeviceScene, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerPatchDeviceScene(orgNodeId, deviceSceneId, patchDeviceScene, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} deviceSceneId 
     * @param {number} deviceScheduleId 
     * @param {PatchDeviceSchedule} [patchDeviceSchedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceSceneControllerApi
     */
    public deviceSceneControllerPatchDeviceSchedule(orgNodeId: number, deviceSceneId: number, deviceScheduleId: number, patchDeviceSchedule?: PatchDeviceSchedule, options?: any) {
        return DeviceSceneControllerApiFp(this.configuration).deviceSceneControllerPatchDeviceSchedule(orgNodeId, deviceSceneId, deviceScheduleId, patchDeviceSchedule, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeControllerApi - axios parameter creator
 * @export
 */
export const NodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerFindNode: async (nodeId: number, filter?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling nodeControllerFindNode.');
            }
            const localVarPath = `/nodes/{node_id}/nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeDevices: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling nodeControllerGetNodeDevices.');
            }
            const localVarPath = `/nodes/{node_id}/devices`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeDevicesLocations: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling nodeControllerGetNodeDevicesLocations.');
            }
            const localVarPath = `/nodes/{node_id}/devices-locations`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeStats: async (nodeId: number, period: 'Day' | 'Week' | 'Month' | 'Hour', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling nodeControllerGetNodeStats.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling nodeControllerGetNodeStats.');
            }
            const localVarPath = `/nodes/{node_id}/stats/{period}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"period"}}`, encodeURIComponent(String(period)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeSummary: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling nodeControllerGetNodeSummary.');
            }
            const localVarPath = `/nodes/{node_id}/summary`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeControllerApi - functional programming interface
 * @export
 */
export const NodeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeControllerFindNode(nodeId: number, filter?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await NodeControllerApiAxiosParamCreator(configuration).nodeControllerFindNode(nodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeControllerGetNodeDevices(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDevices>> {
            const localVarAxiosArgs = await NodeControllerApiAxiosParamCreator(configuration).nodeControllerGetNodeDevices(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeControllerGetNodeDevicesLocations(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceLocation>>> {
            const localVarAxiosArgs = await NodeControllerApiAxiosParamCreator(configuration).nodeControllerGetNodeDevicesLocations(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeControllerGetNodeStats(nodeId: number, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await NodeControllerApiAxiosParamCreator(configuration).nodeControllerGetNodeStats(nodeId, period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeControllerGetNodeSummary(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeSummary>> {
            const localVarAxiosArgs = await NodeControllerApiAxiosParamCreator(configuration).nodeControllerGetNodeSummary(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NodeControllerApi - factory interface
 * @export
 */
export const NodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerFindNode(nodeId: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<Node>> {
            return NodeControllerApiFp(configuration).nodeControllerFindNode(nodeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeDevices(nodeId: number, options?: any): AxiosPromise<NodeDevices> {
            return NodeControllerApiFp(configuration).nodeControllerGetNodeDevices(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeDevicesLocations(nodeId: number, options?: any): AxiosPromise<Array<DeviceLocation>> {
            return NodeControllerApiFp(configuration).nodeControllerGetNodeDevicesLocations(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeStats(nodeId: number, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any): AxiosPromise<any> {
            return NodeControllerApiFp(configuration).nodeControllerGetNodeStats(nodeId, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeControllerGetNodeSummary(nodeId: number, options?: any): AxiosPromise<NodeSummary> {
            return NodeControllerApiFp(configuration).nodeControllerGetNodeSummary(nodeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeControllerApi - object-oriented interface
 * @export
 * @class NodeControllerApi
 * @extends {BaseAPI}
 */
export class NodeControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} nodeId 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeControllerApi
     */
    public nodeControllerFindNode(nodeId: number, filter?: { [key: string]: object; }, options?: any) {
        return NodeControllerApiFp(this.configuration).nodeControllerFindNode(nodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeControllerApi
     */
    public nodeControllerGetNodeDevices(nodeId: number, options?: any) {
        return NodeControllerApiFp(this.configuration).nodeControllerGetNodeDevices(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeControllerApi
     */
    public nodeControllerGetNodeDevicesLocations(nodeId: number, options?: any) {
        return NodeControllerApiFp(this.configuration).nodeControllerGetNodeDevicesLocations(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeControllerApi
     */
    public nodeControllerGetNodeStats(nodeId: number, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any) {
        return NodeControllerApiFp(this.configuration).nodeControllerGetNodeStats(nodeId, period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeControllerApi
     */
    public nodeControllerGetNodeSummary(nodeId: number, options?: any) {
        return NodeControllerApiFp(this.configuration).nodeControllerGetNodeSummary(nodeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeTvClientControllerApi - axios parameter creator
 * @export
 */
export const NodeTvClientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewTvClient} [newTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerCreateTvClient: async (orgNodeId: number, newTvClient?: NewTvClient, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling nodeTvClientControllerCreateTvClient.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/tv-clients`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newTvClient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newTvClient !== undefined ? newTvClient : {}) : (newTvClient || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerDeleteTvClient: async (orgNodeId: number, tvClientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling nodeTvClientControllerDeleteTvClient.');
            }
            // verify required parameter 'tvClientId' is not null or undefined
            if (tvClientId === null || tvClientId === undefined) {
                throw new RequiredError('tvClientId','Required parameter tvClientId was null or undefined when calling nodeTvClientControllerDeleteTvClient.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/tv-clients/{tv_client_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"tv_client_id"}}`, encodeURIComponent(String(tvClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerFindTvClient: async (orgNodeId: number, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling nodeTvClientControllerFindTvClient.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/tv-clients`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {PatchTvClient} [patchTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerPatchTvClient: async (orgNodeId: number, tvClientId: number, patchTvClient?: PatchTvClient, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling nodeTvClientControllerPatchTvClient.');
            }
            // verify required parameter 'tvClientId' is not null or undefined
            if (tvClientId === null || tvClientId === undefined) {
                throw new RequiredError('tvClientId','Required parameter tvClientId was null or undefined when calling nodeTvClientControllerPatchTvClient.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/tv-clients/{tv_client_id}`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)))
                .replace(`{${"tv_client_id"}}`, encodeURIComponent(String(tvClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchTvClient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchTvClient !== undefined ? patchTvClient : {}) : (patchTvClient || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeTvClientControllerApi - functional programming interface
 * @export
 */
export const NodeTvClientControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewTvClient} [newTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTvClientControllerCreateTvClient(orgNodeId: number, newTvClient?: NewTvClient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvClient>> {
            const localVarAxiosArgs = await NodeTvClientControllerApiAxiosParamCreator(configuration).nodeTvClientControllerCreateTvClient(orgNodeId, newTvClient, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTvClientControllerDeleteTvClient(orgNodeId: number, tvClientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NodeTvClientControllerApiAxiosParamCreator(configuration).nodeTvClientControllerDeleteTvClient(orgNodeId, tvClientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTvClientControllerFindTvClient(orgNodeId: number, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await NodeTvClientControllerApiAxiosParamCreator(configuration).nodeTvClientControllerFindTvClient(orgNodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {PatchTvClient} [patchTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTvClientControllerPatchTvClient(orgNodeId: number, tvClientId: number, patchTvClient?: PatchTvClient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NodeTvClientControllerApiAxiosParamCreator(configuration).nodeTvClientControllerPatchTvClient(orgNodeId, tvClientId, patchTvClient, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NodeTvClientControllerApi - factory interface
 * @export
 */
export const NodeTvClientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} orgNodeId 
         * @param {NewTvClient} [newTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerCreateTvClient(orgNodeId: number, newTvClient?: NewTvClient, options?: any): AxiosPromise<TvClient> {
            return NodeTvClientControllerApiFp(configuration).nodeTvClientControllerCreateTvClient(orgNodeId, newTvClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerDeleteTvClient(orgNodeId: number, tvClientId: number, options?: any): AxiosPromise<void> {
            return NodeTvClientControllerApiFp(configuration).nodeTvClientControllerDeleteTvClient(orgNodeId, tvClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerFindTvClient(orgNodeId: number, filter?: string, options?: any): AxiosPromise<Array<any>> {
            return NodeTvClientControllerApiFp(configuration).nodeTvClientControllerFindTvClient(orgNodeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {number} tvClientId 
         * @param {PatchTvClient} [patchTvClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTvClientControllerPatchTvClient(orgNodeId: number, tvClientId: number, patchTvClient?: PatchTvClient, options?: any): AxiosPromise<void> {
            return NodeTvClientControllerApiFp(configuration).nodeTvClientControllerPatchTvClient(orgNodeId, tvClientId, patchTvClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeTvClientControllerApi - object-oriented interface
 * @export
 * @class NodeTvClientControllerApi
 * @extends {BaseAPI}
 */
export class NodeTvClientControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} orgNodeId 
     * @param {NewTvClient} [newTvClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTvClientControllerApi
     */
    public nodeTvClientControllerCreateTvClient(orgNodeId: number, newTvClient?: NewTvClient, options?: any) {
        return NodeTvClientControllerApiFp(this.configuration).nodeTvClientControllerCreateTvClient(orgNodeId, newTvClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} tvClientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTvClientControllerApi
     */
    public nodeTvClientControllerDeleteTvClient(orgNodeId: number, tvClientId: number, options?: any) {
        return NodeTvClientControllerApiFp(this.configuration).nodeTvClientControllerDeleteTvClient(orgNodeId, tvClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTvClientControllerApi
     */
    public nodeTvClientControllerFindTvClient(orgNodeId: number, filter?: string, options?: any) {
        return NodeTvClientControllerApiFp(this.configuration).nodeTvClientControllerFindTvClient(orgNodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {number} tvClientId 
     * @param {PatchTvClient} [patchTvClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTvClientControllerApi
     */
    public nodeTvClientControllerPatchTvClient(orgNodeId: number, tvClientId: number, patchTvClient?: PatchTvClient, options?: any) {
        return NodeTvClientControllerApiFp(this.configuration).nodeTvClientControllerPatchTvClient(orgNodeId, tvClientId, patchTvClient, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgNodeControllerApi - axios parameter creator
 * @export
 */
export const OrgNodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建组织结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerCreateOrgNode: async (nodeId: number, newNodeInNode?: NewNodeInNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerCreateOrgNode.');
            }
            const localVarPath = `/nodes/{node_id}/org-nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newNodeInNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newNodeInNode !== undefined ? newNodeInNode : {}) : (newNodeInNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerDeleteOrgNode: async (nodeId: number, orgNodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerDeleteOrgNode.');
            }
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling orgNodeControllerDeleteOrgNode.');
            }
            const localVarPath = `/nodes/{node_id}/org-nodes/{org_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgAndSpaceNodes: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerGetDescendentOrgAndSpaceNodes.');
            }
            const localVarPath = `/nodes/{node_id}/descendent-org-and-space-nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices.');
            }
            const localVarPath = `/nodes/{node_id}/descendent-org-and-space-nodes-and-located-devices`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgNodes: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerGetDescendentOrgNodes.');
            }
            const localVarPath = `/nodes/{node_id}/descendent-org-nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {PatchOrgNode} [patchOrgNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerPatchOrgNode: async (nodeId: number, orgNodeId: number, patchOrgNode?: PatchOrgNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling orgNodeControllerPatchOrgNode.');
            }
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling orgNodeControllerPatchOrgNode.');
            }
            const localVarPath = `/nodes/{node_id}/org-nodes/{org_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchOrgNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchOrgNode !== undefined ? patchOrgNode : {}) : (patchOrgNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgNodeControllerApi - functional programming interface
 * @export
 */
export const OrgNodeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建组织结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerCreateOrgNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Node>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerCreateOrgNode(nodeId, newNodeInNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerDeleteOrgNode(nodeId: number, orgNodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerDeleteOrgNode(nodeId, orgNodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodesAndLocatedDevices>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerGetDescendentOrgNodes(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerGetDescendentOrgNodes(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {PatchOrgNode} [patchOrgNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgNodeControllerPatchOrgNode(nodeId: number, orgNodeId: number, patchOrgNode?: PatchOrgNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrgNodeControllerApiAxiosParamCreator(configuration).orgNodeControllerPatchOrgNode(nodeId, orgNodeId, patchOrgNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrgNodeControllerApi - factory interface
 * @export
 */
export const OrgNodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 在结点下新建组织结点。
         * @param {number} nodeId 
         * @param {NewNodeInNode} [newNodeInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerCreateOrgNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any): AxiosPromise<Node> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerCreateOrgNode(nodeId, newNodeInNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerDeleteOrgNode(nodeId: number, orgNodeId: number, options?: any): AxiosPromise<void> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerDeleteOrgNode(nodeId, orgNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId: number, options?: any): AxiosPromise<Array<Node>> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId: number, options?: any): AxiosPromise<NodesAndLocatedDevices> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerGetDescendentOrgNodes(nodeId: number, options?: any): AxiosPromise<Array<Node>> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerGetDescendentOrgNodes(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} orgNodeId 
         * @param {PatchOrgNode} [patchOrgNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgNodeControllerPatchOrgNode(nodeId: number, orgNodeId: number, patchOrgNode?: PatchOrgNode, options?: any): AxiosPromise<void> {
            return OrgNodeControllerApiFp(configuration).orgNodeControllerPatchOrgNode(nodeId, orgNodeId, patchOrgNode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgNodeControllerApi - object-oriented interface
 * @export
 * @class OrgNodeControllerApi
 * @extends {BaseAPI}
 */
export class OrgNodeControllerApi extends BaseAPI {
    /**
     * 
     * @summary 在结点下新建组织结点。
     * @param {number} nodeId 
     * @param {NewNodeInNode} [newNodeInNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerCreateOrgNode(nodeId: number, newNodeInNode?: NewNodeInNode, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerCreateOrgNode(nodeId, newNodeInNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} orgNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerDeleteOrgNode(nodeId: number, orgNodeId: number, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerDeleteOrgNode(nodeId, orgNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId: number, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerGetDescendentOrgAndSpaceNodes(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId: number, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerGetDescendentOrgAndSpaceNodesAndLocatedDevices(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerGetDescendentOrgNodes(nodeId: number, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerGetDescendentOrgNodes(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} orgNodeId 
     * @param {PatchOrgNode} [patchOrgNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgNodeControllerApi
     */
    public orgNodeControllerPatchOrgNode(nodeId: number, orgNodeId: number, patchOrgNode?: PatchOrgNode, options?: any) {
        return OrgNodeControllerApiFp(this.configuration).orgNodeControllerPatchOrgNode(nodeId, orgNodeId, patchOrgNode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleControllerApi - axios parameter creator
 * @export
 */
export const RoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {NewPermissionInRole} [newPermissionInRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreatePermission: async (nodeId: number, roleId: number, newPermissionInRole?: NewPermissionInRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerCreatePermission.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerCreatePermission.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}/permissions`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPermissionInRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPermissionInRole !== undefined ? newPermissionInRole : {}) : (newPermissionInRole || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {NewRoleInNode} [newRoleInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreateRole: async (nodeId: number, newRoleInNode?: NewRoleInNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerCreateRole.');
            }
            const localVarPath = `/nodes/{node_id}/roles`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newRoleInNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newRoleInNode !== undefined ? newRoleInNode : {}) : (newRoleInNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeletePermission: async (nodeId: number, roleId: number, permissionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerDeletePermission.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerDeletePermission.');
            }
            // verify required parameter 'permissionId' is not null or undefined
            if (permissionId === null || permissionId === undefined) {
                throw new RequiredError('permissionId','Required parameter permissionId was null or undefined when calling roleControllerDeletePermission.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}/permissions/{permission_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"permission_id"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeleteRole: async (nodeId: number, roleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerDeleteRole.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerDeleteRole.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerFindPermission: async (nodeId: number, roleId: number, filter?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerFindPermission.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerFindPermission.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}/permissions`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerFindRole: async (nodeId: number, filter?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerFindRole.');
            }
            const localVarPath = `/nodes/{node_id}/roles`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {PatchPermission} [patchPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerPatchPermission: async (nodeId: number, roleId: number, permissionId: number, patchPermission?: PatchPermission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerPatchPermission.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerPatchPermission.');
            }
            // verify required parameter 'permissionId' is not null or undefined
            if (permissionId === null || permissionId === undefined) {
                throw new RequiredError('permissionId','Required parameter permissionId was null or undefined when calling roleControllerPatchPermission.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}/permissions/{permission_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"permission_id"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchPermission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchPermission !== undefined ? patchPermission : {}) : (patchPermission || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {PatchRole} [patchRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerPatchRole: async (nodeId: number, roleId: number, patchRole?: PatchRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling roleControllerPatchRole.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling roleControllerPatchRole.');
            }
            const localVarPath = `/nodes/{node_id}/roles/{role_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchRole !== undefined ? patchRole : {}) : (patchRole || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleControllerApi - functional programming interface
 * @export
 */
export const RoleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {NewPermissionInRole} [newPermissionInRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreatePermission(nodeId: number, roleId: number, newPermissionInRole?: NewPermissionInRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permission>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerCreatePermission(nodeId, roleId, newPermissionInRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {NewRoleInNode} [newRoleInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreateRole(nodeId: number, newRoleInNode?: NewRoleInNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerCreateRole(nodeId, newRoleInNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerDeletePermission(nodeId: number, roleId: number, permissionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerDeletePermission(nodeId, roleId, permissionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerDeleteRole(nodeId: number, roleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerDeleteRole(nodeId, roleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerFindPermission(nodeId: number, roleId: number, filter?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Permission>>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerFindPermission(nodeId, roleId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerFindRole(nodeId: number, filter?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerFindRole(nodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {PatchPermission} [patchPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerPatchPermission(nodeId: number, roleId: number, permissionId: number, patchPermission?: PatchPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerPatchPermission(nodeId, roleId, permissionId, patchPermission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {PatchRole} [patchRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerPatchRole(nodeId: number, roleId: number, patchRole?: PatchRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleControllerApiAxiosParamCreator(configuration).roleControllerPatchRole(nodeId, roleId, patchRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoleControllerApi - factory interface
 * @export
 */
export const RoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {NewPermissionInRole} [newPermissionInRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreatePermission(nodeId: number, roleId: number, newPermissionInRole?: NewPermissionInRole, options?: any): AxiosPromise<Permission> {
            return RoleControllerApiFp(configuration).roleControllerCreatePermission(nodeId, roleId, newPermissionInRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {NewRoleInNode} [newRoleInNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreateRole(nodeId: number, newRoleInNode?: NewRoleInNode, options?: any): AxiosPromise<Role> {
            return RoleControllerApiFp(configuration).roleControllerCreateRole(nodeId, newRoleInNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeletePermission(nodeId: number, roleId: number, permissionId: number, options?: any): AxiosPromise<void> {
            return RoleControllerApiFp(configuration).roleControllerDeletePermission(nodeId, roleId, permissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeleteRole(nodeId: number, roleId: number, options?: any): AxiosPromise<void> {
            return RoleControllerApiFp(configuration).roleControllerDeleteRole(nodeId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerFindPermission(nodeId: number, roleId: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<Permission>> {
            return RoleControllerApiFp(configuration).roleControllerFindPermission(nodeId, roleId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerFindRole(nodeId: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<Role>> {
            return RoleControllerApiFp(configuration).roleControllerFindRole(nodeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {number} permissionId 
         * @param {PatchPermission} [patchPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerPatchPermission(nodeId: number, roleId: number, permissionId: number, patchPermission?: PatchPermission, options?: any): AxiosPromise<void> {
            return RoleControllerApiFp(configuration).roleControllerPatchPermission(nodeId, roleId, permissionId, patchPermission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} roleId 
         * @param {PatchRole} [patchRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerPatchRole(nodeId: number, roleId: number, patchRole?: PatchRole, options?: any): AxiosPromise<void> {
            return RoleControllerApiFp(configuration).roleControllerPatchRole(nodeId, roleId, patchRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleControllerApi - object-oriented interface
 * @export
 * @class RoleControllerApi
 * @extends {BaseAPI}
 */
export class RoleControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {NewPermissionInRole} [newPermissionInRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerCreatePermission(nodeId: number, roleId: number, newPermissionInRole?: NewPermissionInRole, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerCreatePermission(nodeId, roleId, newPermissionInRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {NewRoleInNode} [newRoleInNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerCreateRole(nodeId: number, newRoleInNode?: NewRoleInNode, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerCreateRole(nodeId, newRoleInNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {number} permissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerDeletePermission(nodeId: number, roleId: number, permissionId: number, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerDeletePermission(nodeId, roleId, permissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerDeleteRole(nodeId: number, roleId: number, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerDeleteRole(nodeId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerFindPermission(nodeId: number, roleId: number, filter?: { [key: string]: object; }, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerFindPermission(nodeId, roleId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerFindRole(nodeId: number, filter?: { [key: string]: object; }, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerFindRole(nodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {number} permissionId 
     * @param {PatchPermission} [patchPermission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerPatchPermission(nodeId: number, roleId: number, permissionId: number, patchPermission?: PatchPermission, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerPatchPermission(nodeId, roleId, permissionId, patchPermission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} roleId 
     * @param {PatchRole} [patchRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleControllerApi
     */
    public roleControllerPatchRole(nodeId: number, roleId: number, patchRole?: PatchRole, options?: any) {
        return RoleControllerApiFp(this.configuration).roleControllerPatchRole(nodeId, roleId, patchRole, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionControllerApi - axios parameter creator
 * @export
 */
export const SessionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BindDeviceRequest} [bindDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerBindDevice: async (bindDeviceRequest?: BindDeviceRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/bind-device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bindDeviceRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bindDeviceRequest !== undefined ? bindDeviceRequest : {}) : (bindDeviceRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用密码登录
         * @param {LoginWithPasswordRequest} [loginWithPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerCreateSession: async (loginWithPasswordRequest?: LoginWithPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginWithPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginWithPasswordRequest !== undefined ? loginWithPasswordRequest : {}) : (loginWithPasswordRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 登出用户，结束会话。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerDeleteSession: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 生成校验图形
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGenerateCaptcha: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/captcha`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {number} commandSeqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceCommandConfirm: async (deviceSn: string, commandSeqId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerGetDeviceCommandConfirm.');
            }
            // verify required parameter 'commandSeqId' is not null or undefined
            if (commandSeqId === null || commandSeqId === undefined) {
                throw new RequiredError('commandSeqId','Required parameter commandSeqId was null or undefined when calling sessionControllerGetDeviceCommandConfirm.');
            }
            const localVarPath = `/get-device-command-confirm/{device_sn}/{commandSeqId}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)))
                .replace(`{${"commandSeqId"}}`, encodeURIComponent(String(commandSeqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceInfoBySn: async (deviceSn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerGetDeviceInfoBySn.');
            }
            const localVarPath = `/get-device-info/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceStats: async (deviceSn: string, period: 'Day' | 'Week' | 'Month' | 'Hour', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerGetDeviceStats.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling sessionControllerGetDeviceStats.');
            }
            const localVarPath = `/deviceStats/{device_sn}/{period}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)))
                .replace(`{${"period"}}`, encodeURIComponent(String(period)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceStatus: async (deviceSn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerGetDeviceStatus.');
            }
            const localVarPath = `/get-device-status/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetNodeInfoById: async (nodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling sessionControllerGetNodeInfoById.');
            }
            const localVarPath = `/get-node-info/{node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetSessionInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetTvClientSetting: async (tvUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvUid' is not null or undefined
            if (tvUid === null || tvUid === undefined) {
                throw new RequiredError('tvUid','Required parameter tvUid was null or undefined when calling sessionControllerGetTvClientSetting.');
            }
            const localVarPath = `/tv-client-setting/{tvUid}`
                .replace(`{${"tvUid"}}`, encodeURIComponent(String(tvUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerIsBindingDeviceOnline: async (deviceSn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerIsBindingDeviceOnline.');
            }
            const localVarPath = `/is-binding-device-online/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerListDeviceAlertTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-device-alert-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyPasswordRequest} [modifyPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerModifyPassword: async (modifyPasswordRequest?: ModifyPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/user-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof modifyPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modifyPasswordRequest !== undefined ? modifyPasswordRequest : {}) : (modifyPasswordRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 延长会话时间并更新会话token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerRenewSession: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX1000} [commandX1000] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendCommandX1000: async (deviceSn: string, commandX1000?: CommandX1000, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerSendCommandX1000.');
            }
            const localVarPath = `/send-x1000-command/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof commandX1000 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(commandX1000 !== undefined ? commandX1000 : {}) : (commandX1000 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX400} [commandX400] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendCommandX400: async (deviceSn: string, commandX400?: CommandX400, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerSendCommandX400.');
            }
            const localVarPath = `/send-x400-command/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof commandX400 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(commandX400 !== undefined ? commandX400 : {}) : (commandX400 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendRefreshInfoCommand: async (deviceSn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerSendRefreshInfoCommand.');
            }
            const localVarPath = `/send-refresh-info-command/{device_sn}`
                .replace(`{${"device_sn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {SetDeviceSceneRequest} [setDeviceSceneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSetDeviceScene: async (deviceSn: string, setDeviceSceneRequest?: SetDeviceSceneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSn' is not null or undefined
            if (deviceSn === null || deviceSn === undefined) {
                throw new RequiredError('deviceSn','Required parameter deviceSn was null or undefined when calling sessionControllerSetDeviceScene.');
            }
            const localVarPath = `/set-device-scene/{deviceSn}`
                .replace(`{${"deviceSn"}}`, encodeURIComponent(String(deviceSn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setDeviceSceneRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setDeviceSceneRequest !== undefined ? setDeviceSceneRequest : {}) : (setDeviceSceneRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionControllerApi - functional programming interface
 * @export
 */
export const SessionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BindDeviceRequest} [bindDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerBindDevice(bindDeviceRequest?: BindDeviceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BindDeviceReply>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerBindDevice(bindDeviceRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 用密码登录
         * @param {LoginWithPasswordRequest} [loginWithPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerCreateSession(loginWithPasswordRequest?: LoginWithPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginReply>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerCreateSession(loginWithPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 登出用户，结束会话。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerDeleteSession(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerDeleteSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 生成校验图形
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGenerateCaptcha(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Captcha>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGenerateCaptcha(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {number} commandSeqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetDeviceCommandConfirm(deviceSn: string, commandSeqId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceCommandConfirm>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetDeviceCommandConfirm(deviceSn, commandSeqId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetDeviceInfoBySn(deviceSn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetDeviceInfoBySn(deviceSn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetDeviceStats(deviceSn: string, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetDeviceStats(deviceSn, period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetDeviceStatus(deviceSn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetDeviceStatus(deviceSn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetNodeInfoById(nodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeInfo>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetNodeInfoById(nodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 获取用户ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetSessionInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetSessionInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerGetTvClientSetting(tvUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvClient>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerGetTvClientSetting(tvUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerIsBindingDeviceOnline(deviceSn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerIsBindingDeviceOnline(deviceSn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerListDeviceAlertTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerListDeviceAlertTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ModifyPasswordRequest} [modifyPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerModifyPassword(modifyPasswordRequest?: ModifyPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerModifyPassword(modifyPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 延长会话时间并更新会话token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerRenewSession(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerRenewSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX1000} [commandX1000] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerSendCommandX1000(deviceSn: string, commandX1000?: CommandX1000, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerSendCommandX1000(deviceSn, commandX1000, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX400} [commandX400] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerSendCommandX400(deviceSn: string, commandX400?: CommandX400, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerSendCommandX400(deviceSn, commandX400, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerSendRefreshInfoCommand(deviceSn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerSendRefreshInfoCommand(deviceSn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {SetDeviceSceneRequest} [setDeviceSceneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionControllerSetDeviceScene(deviceSn: string, setDeviceSceneRequest?: SetDeviceSceneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionControllerApiAxiosParamCreator(configuration).sessionControllerSetDeviceScene(deviceSn, setDeviceSceneRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SessionControllerApi - factory interface
 * @export
 */
export const SessionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {BindDeviceRequest} [bindDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerBindDevice(bindDeviceRequest?: BindDeviceRequest, options?: any): AxiosPromise<BindDeviceReply> {
            return SessionControllerApiFp(configuration).sessionControllerBindDevice(bindDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用密码登录
         * @param {LoginWithPasswordRequest} [loginWithPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerCreateSession(loginWithPasswordRequest?: LoginWithPasswordRequest, options?: any): AxiosPromise<LoginReply> {
            return SessionControllerApiFp(configuration).sessionControllerCreateSession(loginWithPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 登出用户，结束会话。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerDeleteSession(options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerDeleteSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 生成校验图形
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGenerateCaptcha(options?: any): AxiosPromise<Captcha> {
            return SessionControllerApiFp(configuration).sessionControllerGenerateCaptcha(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {number} commandSeqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceCommandConfirm(deviceSn: string, commandSeqId: number, options?: any): AxiosPromise<DeviceCommandConfirm> {
            return SessionControllerApiFp(configuration).sessionControllerGetDeviceCommandConfirm(deviceSn, commandSeqId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceInfoBySn(deviceSn: string, options?: any): AxiosPromise<any> {
            return SessionControllerApiFp(configuration).sessionControllerGetDeviceInfoBySn(deviceSn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceStats(deviceSn: string, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any): AxiosPromise<any> {
            return SessionControllerApiFp(configuration).sessionControllerGetDeviceStats(deviceSn, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetDeviceStatus(deviceSn: string, options?: any): AxiosPromise<any> {
            return SessionControllerApiFp(configuration).sessionControllerGetDeviceStatus(deviceSn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetNodeInfoById(nodeId: number, options?: any): AxiosPromise<NodeInfo> {
            return SessionControllerApiFp(configuration).sessionControllerGetNodeInfoById(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetSessionInfo(options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerGetSessionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerGetTvClientSetting(tvUid: string, options?: any): AxiosPromise<TvClient> {
            return SessionControllerApiFp(configuration).sessionControllerGetTvClientSetting(tvUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerIsBindingDeviceOnline(deviceSn: string, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerIsBindingDeviceOnline(deviceSn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerListDeviceAlertTypes(options?: any): AxiosPromise<any> {
            return SessionControllerApiFp(configuration).sessionControllerListDeviceAlertTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyPasswordRequest} [modifyPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerModifyPassword(modifyPasswordRequest?: ModifyPasswordRequest, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerModifyPassword(modifyPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 延长会话时间并更新会话token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerRenewSession(options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerRenewSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX1000} [commandX1000] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendCommandX1000(deviceSn: string, commandX1000?: CommandX1000, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerSendCommandX1000(deviceSn, commandX1000, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {CommandX400} [commandX400] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendCommandX400(deviceSn: string, commandX400?: CommandX400, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerSendCommandX400(deviceSn, commandX400, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSendRefreshInfoCommand(deviceSn: string, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerSendRefreshInfoCommand(deviceSn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceSn 
         * @param {SetDeviceSceneRequest} [setDeviceSceneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionControllerSetDeviceScene(deviceSn: string, setDeviceSceneRequest?: SetDeviceSceneRequest, options?: any): AxiosPromise<void> {
            return SessionControllerApiFp(configuration).sessionControllerSetDeviceScene(deviceSn, setDeviceSceneRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionControllerApi - object-oriented interface
 * @export
 * @class SessionControllerApi
 * @extends {BaseAPI}
 */
export class SessionControllerApi extends BaseAPI {
    /**
     * 
     * @param {BindDeviceRequest} [bindDeviceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerBindDevice(bindDeviceRequest?: BindDeviceRequest, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerBindDevice(bindDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用密码登录
     * @param {LoginWithPasswordRequest} [loginWithPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerCreateSession(loginWithPasswordRequest?: LoginWithPasswordRequest, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerCreateSession(loginWithPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 登出用户，结束会话。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerDeleteSession(options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerDeleteSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 生成校验图形
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGenerateCaptcha(options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGenerateCaptcha(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {number} commandSeqId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetDeviceCommandConfirm(deviceSn: string, commandSeqId: number, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetDeviceCommandConfirm(deviceSn, commandSeqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetDeviceInfoBySn(deviceSn: string, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetDeviceInfoBySn(deviceSn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {'Day' | 'Week' | 'Month' | 'Hour'} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetDeviceStats(deviceSn: string, period: 'Day' | 'Week' | 'Month' | 'Hour', options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetDeviceStats(deviceSn, period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetDeviceStatus(deviceSn: string, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetDeviceStatus(deviceSn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetNodeInfoById(nodeId: number, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetNodeInfoById(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetSessionInfo(options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetSessionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerGetTvClientSetting(tvUid: string, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerGetTvClientSetting(tvUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerIsBindingDeviceOnline(deviceSn: string, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerIsBindingDeviceOnline(deviceSn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerListDeviceAlertTypes(options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerListDeviceAlertTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyPasswordRequest} [modifyPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerModifyPassword(modifyPasswordRequest?: ModifyPasswordRequest, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerModifyPassword(modifyPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 延长会话时间并更新会话token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerRenewSession(options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerRenewSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {CommandX1000} [commandX1000] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerSendCommandX1000(deviceSn: string, commandX1000?: CommandX1000, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerSendCommandX1000(deviceSn, commandX1000, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {CommandX400} [commandX400] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerSendCommandX400(deviceSn: string, commandX400?: CommandX400, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerSendCommandX400(deviceSn, commandX400, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerSendRefreshInfoCommand(deviceSn: string, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerSendRefreshInfoCommand(deviceSn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceSn 
     * @param {SetDeviceSceneRequest} [setDeviceSceneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionControllerApi
     */
    public sessionControllerSetDeviceScene(deviceSn: string, setDeviceSceneRequest?: SetDeviceSceneRequest, options?: any) {
        return SessionControllerApiFp(this.configuration).sessionControllerSetDeviceScene(deviceSn, setDeviceSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpaceNodeControllerApi - axios parameter creator
 * @export
 */
export const SpaceNodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建空间结点。
         * @param {number} nodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerCreateSpaceNode: async (nodeId: number, spaceNode?: SpaceNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling spaceNodeControllerCreateSpaceNode.');
            }
            const localVarPath = `/nodes/{node_id}/space-nodes`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof spaceNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spaceNode !== undefined ? spaceNode : {}) : (spaceNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerDeleteSpaceNode: async (nodeId: number, spaceNodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling spaceNodeControllerDeleteSpaceNode.');
            }
            // verify required parameter 'spaceNodeId' is not null or undefined
            if (spaceNodeId === null || spaceNodeId === undefined) {
                throw new RequiredError('spaceNodeId','Required parameter spaceNodeId was null or undefined when calling spaceNodeControllerDeleteSpaceNode.');
            }
            const localVarPath = `/nodes/{node_id}/space-nodes/{space_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"space_node_id"}}`, encodeURIComponent(String(spaceNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerGetDescendentSpace: async (orgNodeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgNodeId' is not null or undefined
            if (orgNodeId === null || orgNodeId === undefined) {
                throw new RequiredError('orgNodeId','Required parameter orgNodeId was null or undefined when calling spaceNodeControllerGetDescendentSpace.');
            }
            const localVarPath = `/org-nodes/{org_node_id}/descendent-space`
                .replace(`{${"org_node_id"}}`, encodeURIComponent(String(orgNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerPatchSpaceNode: async (nodeId: number, spaceNodeId: number, spaceNode?: SpaceNode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling spaceNodeControllerPatchSpaceNode.');
            }
            // verify required parameter 'spaceNodeId' is not null or undefined
            if (spaceNodeId === null || spaceNodeId === undefined) {
                throw new RequiredError('spaceNodeId','Required parameter spaceNodeId was null or undefined when calling spaceNodeControllerPatchSpaceNode.');
            }
            const localVarPath = `/nodes/{node_id}/space-nodes/{space_node_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"space_node_id"}}`, encodeURIComponent(String(spaceNodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof spaceNode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spaceNode !== undefined ? spaceNode : {}) : (spaceNode || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaceNodeControllerApi - functional programming interface
 * @export
 */
export const SpaceNodeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 在结点下新建空间结点。
         * @param {number} nodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceNodeControllerCreateSpaceNode(nodeId: number, spaceNode?: SpaceNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Node>> {
            const localVarAxiosArgs = await SpaceNodeControllerApiAxiosParamCreator(configuration).spaceNodeControllerCreateSpaceNode(nodeId, spaceNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceNodeControllerDeleteSpaceNode(nodeId: number, spaceNodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SpaceNodeControllerApiAxiosParamCreator(configuration).spaceNodeControllerDeleteSpaceNode(nodeId, spaceNodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceNodeControllerGetDescendentSpace(orgNodeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescendentSpace>> {
            const localVarAxiosArgs = await SpaceNodeControllerApiAxiosParamCreator(configuration).spaceNodeControllerGetDescendentSpace(orgNodeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spaceNodeControllerPatchSpaceNode(nodeId: number, spaceNodeId: number, spaceNode?: SpaceNode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SpaceNodeControllerApiAxiosParamCreator(configuration).spaceNodeControllerPatchSpaceNode(nodeId, spaceNodeId, spaceNode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpaceNodeControllerApi - factory interface
 * @export
 */
export const SpaceNodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 在结点下新建空间结点。
         * @param {number} nodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerCreateSpaceNode(nodeId: number, spaceNode?: SpaceNode, options?: any): AxiosPromise<Node> {
            return SpaceNodeControllerApiFp(configuration).spaceNodeControllerCreateSpaceNode(nodeId, spaceNode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerDeleteSpaceNode(nodeId: number, spaceNodeId: number, options?: any): AxiosPromise<void> {
            return SpaceNodeControllerApiFp(configuration).spaceNodeControllerDeleteSpaceNode(nodeId, spaceNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orgNodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerGetDescendentSpace(orgNodeId: number, options?: any): AxiosPromise<DescendentSpace> {
            return SpaceNodeControllerApiFp(configuration).spaceNodeControllerGetDescendentSpace(orgNodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} spaceNodeId 
         * @param {SpaceNode} [spaceNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spaceNodeControllerPatchSpaceNode(nodeId: number, spaceNodeId: number, spaceNode?: SpaceNode, options?: any): AxiosPromise<void> {
            return SpaceNodeControllerApiFp(configuration).spaceNodeControllerPatchSpaceNode(nodeId, spaceNodeId, spaceNode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaceNodeControllerApi - object-oriented interface
 * @export
 * @class SpaceNodeControllerApi
 * @extends {BaseAPI}
 */
export class SpaceNodeControllerApi extends BaseAPI {
    /**
     * 
     * @summary 在结点下新建空间结点。
     * @param {number} nodeId 
     * @param {SpaceNode} [spaceNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceNodeControllerApi
     */
    public spaceNodeControllerCreateSpaceNode(nodeId: number, spaceNode?: SpaceNode, options?: any) {
        return SpaceNodeControllerApiFp(this.configuration).spaceNodeControllerCreateSpaceNode(nodeId, spaceNode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} spaceNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceNodeControllerApi
     */
    public spaceNodeControllerDeleteSpaceNode(nodeId: number, spaceNodeId: number, options?: any) {
        return SpaceNodeControllerApiFp(this.configuration).spaceNodeControllerDeleteSpaceNode(nodeId, spaceNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orgNodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceNodeControllerApi
     */
    public spaceNodeControllerGetDescendentSpace(orgNodeId: number, options?: any) {
        return SpaceNodeControllerApiFp(this.configuration).spaceNodeControllerGetDescendentSpace(orgNodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} spaceNodeId 
     * @param {SpaceNode} [spaceNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceNodeControllerApi
     */
    public spaceNodeControllerPatchSpaceNode(nodeId: number, spaceNodeId: number, spaceNode?: SpaceNode, options?: any) {
        return SpaceNodeControllerApiFp(this.configuration).spaceNodeControllerPatchSpaceNode(nodeId, spaceNodeId, spaceNode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TvClientControllerApi - axios parameter creator
 * @export
 */
export const TvClientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplay: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplay.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceLocations: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayDeviceLocations.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-device-locations`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceStats: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayDeviceStats.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-device-stats`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceStatus: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayDeviceStatus.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-device-status`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceWeather: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayDeviceWeather.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-device-weather`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayNodeSummary: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayNodeSummary.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-node-summary`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayNodeWeather: async (tvClientUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tvClientUid' is not null or undefined
            if (tvClientUid === null || tvClientUid === undefined) {
                throw new RequiredError('tvClientUid','Required parameter tvClientUid was null or undefined when calling tvClientControllerGetTvClientDisplayNodeWeather.');
            }
            const localVarPath = `/tv-clients/{tv_client_uid}/display-node-weather`
                .replace(`{${"tv_client_uid"}}`, encodeURIComponent(String(tvClientUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TvClientControllerApi - functional programming interface
 * @export
 */
export const TvClientControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplay(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvClientDisplay>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplay(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceLocation>>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeSummary>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await TvClientControllerApiAxiosParamCreator(configuration).tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TvClientControllerApi - factory interface
 * @export
 */
export const TvClientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplay(tvClientUid: string, options?: any): AxiosPromise<TvClientDisplay> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplay(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid: string, options?: any): AxiosPromise<Array<DeviceLocation>> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid: string, options?: any): AxiosPromise<any> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid: string, options?: any): AxiosPromise<any> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid: string, options?: any): AxiosPromise<any> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid: string, options?: any): AxiosPromise<NodeSummary> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tvClientUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid: string, options?: any): AxiosPromise<any> {
            return TvClientControllerApiFp(configuration).tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TvClientControllerApi - object-oriented interface
 * @export
 * @class TvClientControllerApi
 * @extends {BaseAPI}
 */
export class TvClientControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplay(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplay(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayDeviceLocations(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayDeviceStats(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayDeviceStatus(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayDeviceWeather(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayNodeSummary(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tvClientUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvClientControllerApi
     */
    public tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid: string, options?: any) {
        return TvClientControllerApiFp(this.configuration).tvClientControllerGetTvClientDisplayNodeWeather(tvClientUid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser: async (nodeId: number, newUser?: NewUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerCreateUser.');
            }
            const localVarPath = `/nodes/{node_id}/users`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newUser !== undefined ? newUser : {}) : (newUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {NewUserRoleInUser} [newUserRoleInUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUserRole: async (nodeId: number, userId: number, newUserRoleInUser?: NewUserRoleInUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerCreateUserRole.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling userControllerCreateUserRole.');
            }
            const localVarPath = `/nodes/{node_id}/users/{user_id}/user-roles`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newUserRoleInUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newUserRoleInUser !== undefined ? newUserRoleInUser : {}) : (newUserRoleInUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUser: async (nodeId: number, userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerDeleteUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling userControllerDeleteUser.');
            }
            const localVarPath = `/nodes/{node_id}/users/{user_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUserRole: async (nodeId: number, userId: number, roleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerDeleteUserRole.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling userControllerDeleteUserRole.');
            }
            // verify required parameter 'roleId' is not null or undefined
            if (roleId === null || roleId === undefined) {
                throw new RequiredError('roleId','Required parameter roleId was null or undefined when calling userControllerDeleteUserRole.');
            }
            const localVarPath = `/nodes/{node_id}/users/{user_id}/user-roles/{role_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUser: async (nodeId: number, filter?: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerFindUser.');
            }
            const localVarPath = `/nodes/{node_id}/users`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUserRole: async (nodeId: number, userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerFindUserRole.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling userControllerFindUserRole.');
            }
            const localVarPath = `/nodes/{node_id}/users/{user_id}/user-roles`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {PatchUser} [patchUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPatchUser: async (nodeId: number, userId: number, patchUser?: PatchUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new RequiredError('nodeId','Required parameter nodeId was null or undefined when calling userControllerPatchUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling userControllerPatchUser.');
            }
            const localVarPath = `/nodes/{node_id}/users/{user_id}`
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication xuanwu required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-XuanWu-Token")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-XuanWu-Token"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchUser !== undefined ? patchUser : {}) : (patchUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUser(nodeId: number, newUser?: NewUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerCreateUser(nodeId, newUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {NewUserRoleInUser} [newUserRoleInUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUserRole(nodeId: number, userId: number, newUserRoleInUser?: NewUserRoleInUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRole>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerCreateUserRole(nodeId, userId, newUserRoleInUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteUser(nodeId: number, userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerDeleteUser(nodeId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteUserRole(nodeId: number, userId: number, roleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerDeleteUserRole(nodeId, userId, roleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindUser(nodeId: number, filter?: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerFindUser(nodeId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindUserRole(nodeId: number, userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerFindUserRole(nodeId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {PatchUser} [patchUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerPatchUser(nodeId: number, userId: number, patchUser?: PatchUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).userControllerPatchUser(nodeId, userId, patchUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} nodeId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser(nodeId: number, newUser?: NewUser, options?: any): AxiosPromise<User> {
            return UserControllerApiFp(configuration).userControllerCreateUser(nodeId, newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {NewUserRoleInUser} [newUserRoleInUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUserRole(nodeId: number, userId: number, newUserRoleInUser?: NewUserRoleInUser, options?: any): AxiosPromise<UserRole> {
            return UserControllerApiFp(configuration).userControllerCreateUserRole(nodeId, userId, newUserRoleInUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUser(nodeId: number, userId: number, options?: any): AxiosPromise<void> {
            return UserControllerApiFp(configuration).userControllerDeleteUser(nodeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteUserRole(nodeId: number, userId: number, roleId: number, options?: any): AxiosPromise<void> {
            return UserControllerApiFp(configuration).userControllerDeleteUserRole(nodeId, userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUser(nodeId: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<User>> {
            return UserControllerApiFp(configuration).userControllerFindUser(nodeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUserRole(nodeId: number, userId: number, options?: any): AxiosPromise<Array<Role>> {
            return UserControllerApiFp(configuration).userControllerFindUserRole(nodeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} nodeId 
         * @param {number} userId 
         * @param {PatchUser} [patchUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPatchUser(nodeId: number, userId: number, patchUser?: PatchUser, options?: any): AxiosPromise<void> {
            return UserControllerApiFp(configuration).userControllerPatchUser(nodeId, userId, patchUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} nodeId 
     * @param {NewUser} [newUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerCreateUser(nodeId: number, newUser?: NewUser, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerCreateUser(nodeId, newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} userId 
     * @param {NewUserRoleInUser} [newUserRoleInUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerCreateUserRole(nodeId: number, userId: number, newUserRoleInUser?: NewUserRoleInUser, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerCreateUserRole(nodeId, userId, newUserRoleInUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerDeleteUser(nodeId: number, userId: number, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerDeleteUser(nodeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} userId 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerDeleteUserRole(nodeId: number, userId: number, roleId: number, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerDeleteUserRole(nodeId, userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerFindUser(nodeId: number, filter?: { [key: string]: object; }, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerFindUser(nodeId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerFindUserRole(nodeId: number, userId: number, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerFindUserRole(nodeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} nodeId 
     * @param {number} userId 
     * @param {PatchUser} [patchUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerPatchUser(nodeId: number, userId: number, patchUser?: PatchUser, options?: any) {
        return UserControllerApiFp(this.configuration).userControllerPatchUser(nodeId, userId, patchUser, options).then((request) => request(this.axios, this.basePath));
    }
}


