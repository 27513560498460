// src/access.ts
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  
  if (!currentUser) {
    return false;
  }

  const {isSuperRoot, permissions} = currentUser;

  const result = {
    canAccount: isSuperRoot || Boolean(currentUser),
    canDefault: isSuperRoot || findOpInPermissions('opset.default', permissions),
    canOperation: isSuperRoot || findOpInPermissions('opset.operation', permissions),
    canConfig: isSuperRoot || findOpInPermissions('opset.config', permissions),
  };
  console.log(result);

  return result;
}

function findOpInPermissions(op: string, permissions?: any[]) {
  if (!permissions) {
    return false;
  }
  return permissions.some(perm => perm.operations.indexOf(op) >= 0);
}