// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/code/xuanwu-web/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/code/xuanwu-web/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "name": "login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/code/xuanwu-web/src/pages/user/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/operation",
        "name": "operation",
        "icon": "DashboardOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation' */'/code/xuanwu-web/src/pages/Operation'), loading: LoadingComponent}),
        "access": "canDefault",
        "routes": [
          {
            "path": "/operation/:nodeKey",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__OperationNode' */'/code/xuanwu-web/src/pages/Operation/OperationNode'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/operation/:nodeKey/summary",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Summary' */'/code/xuanwu-web/src/pages/Operation/Summary'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation/:nodeKey/devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operation__Devices' */'/code/xuanwu-web/src/pages/Operation/Devices'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/operator-client",
        "name": "operatorClient",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient' */'/code/xuanwu-web/src/pages/OperatorClient'), loading: LoadingComponent}),
        "access": "canDefault",
        "layout": {
          "hideMenu": true,
          "hideNav": true
        },
        "routes": [
          {
            "path": "/operator-client/install-puri-x",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__InstallPuriX' */'/code/xuanwu-web/src/pages/OperatorClient/InstallPuriX'), loading: LoadingComponent}),
            "access": "canConfig",
            "exact": true
          },
          {
            "path": "/operator-client/test-puri-x",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__TestPuriX' */'/code/xuanwu-web/src/pages/OperatorClient/TestPuriX'), loading: LoadingComponent}),
            "access": "canConfig",
            "exact": true
          },
          {
            "path": "/operator-client/send-command/:deviceTypeShort",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__SendCommand' */'/code/xuanwu-web/src/pages/OperatorClient/SendCommand'), loading: LoadingComponent}),
            "access": "canConfig",
            "exact": true
          },
          {
            "path": "/operator-client/select-space",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__SelectSpace' */'/code/xuanwu-web/src/pages/OperatorClient/SelectSpace'), loading: LoadingComponent}),
            "access": "canConfig",
            "exact": true
          },
          {
            "path": "/operator-client/scan-tv-client-code",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__ScanTvClientCode' */'/code/xuanwu-web/src/pages/OperatorClient/ScanTvClientCode'), loading: LoadingComponent}),
            "access": "canDefault",
            "exact": true
          },
          {
            "path": "/operator-client/tv-client-setting/:tvUid",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__TvClientSetting' */'/code/xuanwu-web/src/pages/OperatorClient/TvClientSetting'), loading: LoadingComponent}),
            "access": "canConfig",
            "routes": [
              {
                "path": "/operator-client/tv-client-setting/:tvUid/tv-client-select-org-node",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__TvClientSelectOrgNode' */'/code/xuanwu-web/src/pages/OperatorClient/TvClientSelectOrgNode'), loading: LoadingComponent}),
                "access": "canConfig",
                "exact": true
              },
              {
                "path": "/operator-client/tv-client-setting/:tvUid/tv-client-select-display-content/:orgNodeId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperatorClient__TvClientSelectDisplayContent' */'/code/xuanwu-web/src/pages/OperatorClient/TvClientSelectDisplayContent'), loading: LoadingComponent}),
                "access": "canConfig",
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/config",
        "name": "config",
        "icon": "SettingOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement' */'/code/xuanwu-web/src/pages/ConfigManagement'), loading: LoadingComponent}),
        "access": "canConfig",
        "routes": [
          {
            "path": "/config/:orgNodeKey",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__ConfigOrgNode' */'/code/xuanwu-web/src/pages/ConfigManagement/ConfigOrgNode'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/config/:orgNodeKey/org",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditOrg' */'/code/xuanwu-web/src/pages/ConfigManagement/EditOrg'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/space",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditSpace' */'/code/xuanwu-web/src/pages/ConfigManagement/EditSpace'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/cate",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditCate' */'/code/xuanwu-web/src/pages/ConfigManagement/EditCate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/device",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditDevice' */'/code/xuanwu-web/src/pages/ConfigManagement/EditDevice'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/user",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditUser' */'/code/xuanwu-web/src/pages/ConfigManagement/EditUser'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/scene",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditScene' */'/code/xuanwu-web/src/pages/ConfigManagement/EditScene'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/config/:orgNodeKey/tv-client",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ConfigManagement__EditTvClient' */'/code/xuanwu-web/src/pages/ConfigManagement/EditTvClient'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/account",
        "name": "account",
        "icon": "UserOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account' */'/code/xuanwu-web/src/pages/Account'), loading: LoadingComponent}),
        "access": "canAccount",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/operation",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/code/xuanwu-web/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
