export default {
  'app.title': '玄武2000',
  'login.tab.account': '账户密码登录',
  'login.tab.mobile': '手机号登录',

  'xuanwuApi.error.wrongAccount': '账户或密码错误',
  'xuanwuApi.error.wrongCaptcha': '图形验证码错误',
  // 'login.smscode.error': '短信验证码错误',
  'network.error': '网络错误，请稍后重试',
  'network.error.unknown': '网络错误，请联系技术支持',
  'xuanwuApi.ok': '成功',
  'xuanwuApi.ok.modifyPassword': '修改密码成功',
  'layout.global.error.title': '应用错误',
  'layout.global.error.stack': '错误堆栈',
  'xuanwuApi.error.accessDenied': '不具备操作权限',
  'xuanwuApi.error.invalidDeviceSn': '设备SN码不符合规范',
  'xuanwuApi.error.deviceTypeNotFound': '找不到对应的设备型号',
  'xuanwuApi.error.sameDeviceSnExists': '相同的SN码已存在',
  'xuanwuApi.error.sameNameExistsInSameLevel': '存在相同名称的同一层结点',
  'xuanwuApi.error.cannotBindToNonLeafSpaceNode': '不能绑定到有子空间的结点',
  'xuanwuApi.error.sameUserNameExists': '相同的用户名已存在',
  'xuanwuApi.error.wrongOldPassword': '原密码输入错误',
  'xuanwuApi.error.deviceSnNotFound': '设备SN码找不到',
  'xuanwuApi.error.sameSceneNameExists': '相同的场景名称已存在',
  'xuanwuApi.error.notAllow': '不具备操作权限',
  'xuanwuApi.error.sendCommandJam': '指令被阻塞',
  'xuanwuApi.error.deviceOffline': '设备离线',
  'xuanwuApi.error.cannotBindOfflineDevice': '设备离线，无法绑定',
  'xuanwuApi.error.scheduleCollide': '和已有的计划时间冲突',
  'xuanwuApi.error.sameTvClientNameExists': '相同的名称已存在',
  'xuanwuApi.error.incorrectTvClientUid': '错误的UID',

  'role.admin': '管理员',
  'role.operator': '运维人员',
  'role.readonly': '普通用户',

  'login.input.username': '用户名',
  'login.input.username.require': '请输入用户名！',
  'login.input.password': '密码',
  'login.input.password.require': '请输入密码！',
  'login.input.captcha': '图形验证码',
  'login.input.captcha.require': '请输入图形验证码！',
  'login.input.mobile': '手机号',
  'login.input.mobile.require': '请输入手机号！',
  'login.input.mobile.formatError': '手机号格式错误！',
  'login.input.smscode': '短信验证码',
  'login.input.smscode.require': '请输入短信验证码！',
  'login.input.smscode.getCode': '获取验证码',
  'login.input.smscode.second': '秒',
  'login.message.success': '登录成功！',
  'login.message.error': '无法登录，请稍后重试！',

  'login.login': '登录',
  'menu.login': '登录',
  'menu.account': '个人设置',
  'menu.operation': '日常运维',
  'menu.config': '配置管理',
  'menu.operatorClient': '运维客户端',

  'menu.user.setting': '个人设置',
  'menu.user.logout': '退出登录',
  'dialog.ok': '确定',
  'dialog.submit': '提交',
  'dialog.cancel': '取消',
  'account.modifyPassword.oldPassword': '原密码',
  'account.modifyPassword.oldPassword.require': '请输入原密码！',
  'account.modifyPassword.newPassword': '新密码',
  'account.modifyPassword.newPassword.require': '请输入新密码！',
  'account.modifyPassword.confirmPassword': '确认新密码',
  'account.modifyPassword.confirmPassword.require': '请输入确认新密码！',
  'account.modifyPassword.confirmPassword.notMatch': '新密码和确认输入不一致！',
  'account.modifyPassword.submit': '提交修改',
  'config.orgTree': '组织树',
  'config.tab.org': '组织',
  'config.tab.space': '空间',
  'config.tab.cate': '分类',
  'config.tab.device': '设备',
  'config.tab.scene': '智能场景',
  'config.tab.user': '用户',
  'config.tab.role': '角色',
  'config.tab.tv-client': '大屏显示',
  'config.tab.simcard': 'SIM卡',
  'config.empty': '请在左侧选择一个组织结点',
  'config.org.addNewChild': '新增子组织',
  'config.org.modifyOrgNode': '修改组织',
  'config.org.deleteOrgNode': '删除组织',
  'config.org.name': '组织名称',
  'config.org.name.require': '请输入组织名称！',
  'config.org.deleteOrgNode.confirm': '删除组织“{orgNodeName}”，确认吗？',
  'config.space.addNewChild': '新增子空间',
  'config.space.modifySpaceNode': '修改空间',
  'config.space.deleteSpaceNode': '删除空间',
  'config.space.name': '空间名称',
  'config.space.name.require': '请输入空间名称！',
  'config.space.cate': '分类',
  'config.space.deleteSpaceNode.confirm': '删除空间“{spaceNodeName}”，确认吗？',
  'config.cate.addNewChild': '新增分类',
  'config.cate.modifySpaceNode': '修改分类',
  'config.cate.addNewChild.short': '新增',
  'config.cate.modifySpaceNode.short': '修改',
  'config.cate.deleteSpaceNode.short': '删除',
  'config.cate.name': '分类名称',
  'config.cate.name.require': '请输入分类名称！',
  'config.cate.deleteCateNode.confirm': '删除分类“{cateNodeName}”，确认吗？',
  'config.cate.associatedSpaceNodes': '分类的关联空间结点',
  'config.device.sn': 'SN号',
  'config.device.productName': '产品名称',
  'config.device.locatedSpace': '绑定空间',
  'config.device.addDevices': '批量新增设备',
  'config.device.bindDevice': '绑定设备',
  'config.device.unbindDevice': '解绑设备',
  'config.device.unbindDevice.confirm': '解绑设备“{deviceSn}”，确认吗？',
  'config.device.deleteDevice': '删除设备',
  'config.device.deleteDevice.confirm': '删除设备“{deviceSn}”，确认吗？',
  'config.device.multiSn': '新增设备的SN号（每行一个）',
  'config.device.multiSn.require': '请输入新增的SN号！',
  'config.user.addNewUser': '新增用户',
  'config.user.modifyUser': '修改用户',
  'config.user.deleteUser': '删除用户',
  'config.user.deleteUser.confirm': '删除用户“{userName}”，确认吗？',
  'config.user.userName': '用户名',
  'config.user.userName.require': '请输入用户名！',
  'config.user.password': '密码',
  'config.user.password.require': '请输入密码！',
  'config.user.resetPassword': '重置密码',
  'config.user.userRoles': '用户角色',
  'config.scene.addNewScene': '新增场景',
  'config.scene.modifyScene': '修改场景',
  'config.scene.deleteScene': '删除场景',
  'config.scene.deleteScene.confirm': '删除场景“{sceneName}”，确认吗？',
  'config.scene.deviceSceneName': '场景名称',
  'config.scene.deviceSceneName.require': '请输入场景名称！',
  'config.scene.deviceTypeShort': '设备类型',
  'config.scene.deviceTypeShort.require': '请输入设备类型！',
  'config.scene.isoCountryCode': '国家/地区',
  'config.scene.isoCountryCode.require': '请输入国家/地区！',
  'config.scene.schedule': '计划',
  'config.scene.schedule.setting': '设置',
  'config.scene.schedule.title': '设置“{sceneName}”的计划',
  'config.scene.addNewSchedule': '新增计划',
  'config.scene.modifySchedule': '修改计划',
  'config.scene.deleteSchedule': '删除计划',
  'config.scene.deleteSchedule.confirm': '删除场景“{sceneName}”在{scheduleTime}的计划，确认吗？',
  'config.scene.scheduleDayType': '日期',
  'config.scene.scheduleTime': '时间',
  'config.scene.scheduleTime.require': '请输入时间！',
  'config.scene.x400Command': 'X400指令',
  'config.scene.x1000Command': 'X1000指令',
  'config.scene.scheduleCommand': '指令',
  'config.scene.scheduleRuleset': '规则集',
  'config.tv-client.addNewTvClient': '新增大屏显示',
  'config.tv-client.modifyTvClient': '修改大屏显示',
  'config.tv-client.deleteTvClient': '删除大屏显示',
  'config.tv-client.deleteTvClient.confirm': '删除大屏显示“{tvClientName}”，确认吗？',
  'config.tv-client.tvClientUid': '大屏客户端UID',
  'config.tv-client.tvClientUid.require': '请输入大屏客户端UID！',
  'config.tv-client.online': '在线',
  'config.tv-client.tvClientName': '大屏名称',
  'config.tv-client.tvClientName.require': '请输入大屏名称！',
  'config.tv-client.displayType': '显示类型',
  'config.tv-client.displayType.Device': '设备',
  'config.tv-client.displayType.Node': '空间',
  'config.tv-client.displayItem': '显示内容',
  'config.tv-client.displayStyle': '显示风格',
  'config.tv-client.displayStyle.require': '请输入显示风格！',
  'config.tv-client.displayStyle.FullScreen': '全屏',
  'config.tv-client.displayStyle.Top': '屏幕顶部',
  'config.tv-client.displayStyle.Bottom': '屏幕底部',
  'config.ruleset.deviceRuleset': '规则集',
  'config.ruleset.addNewRule': '新增规则',
  'config.ruleset.deleteRule': '删除',
  'config.ruleset.ruleOrder': '执行顺序',
  'config.ruleset.x400Condition': 'X400条件',
  'config.ruleset.x1000Condition': 'X1000条件',
  'config.ruleset.x400Command': 'X400指令',
  'config.ruleset.x1000Command': 'X1000指令',

  'operation.tab.summary': '统计概要',
  'operation.tab.devices': '设备列表',

  'device.function.powerOn': '机器开关',
  'device.function.modeAndShift': '风速档位',
  'device.function.lockOn': '童锁',
  'device.function.uvOn': '紫外线',
  'device.function.anionOn': '等离子',
  'device.function.ledOff': '熄屏',
  'device.function.beepOn': '按键音',
  'device.function.colorLightMode': '氛围灯',

  'device.function.modeAndShift.1': '睡眠档',
  'device.function.modeAndShift.2': '自动档',
  'device.function.modeAndShift.3': '风速1档',
  'device.function.modeAndShift.4': '风速2档',
  'device.function.modeAndShift.5': '风速3档',
  'device.function.modeAndShift.6': '风速4档',
  'device.function.modeAndShift.7': '风速5档',
  'device.function.modeAndShift.8': '风速6档',

  'device.mode.offline': '离线',
  'device.mode.powerOff': '关机',
  'device.mode.noPowerSupply': '电源断开',
  'device.mode.Mode_0_Shift_0': '手动1档',
  'device.mode.Mode_0_Shift_1': '手动2档',
  'device.mode.Mode_0_Shift_2': '手动3档',
  'device.mode.Mode_0_Shift_3': '手动4档',
  'device.mode.Mode_0_Shift_4': '手动5档',
  'device.mode.Mode_0_Shift_5': '手动6档',
  'device.mode.Mode_1_Shift_0': '自动1档',
  'device.mode.Mode_1_Shift_1': '自动2档',
  'device.mode.Mode_1_Shift_2': '自动3档',
  'device.mode.Mode_1_Shift_3': '自动4档',
  'device.mode.Mode_1_Shift_4': '自动5档',
  'device.mode.Mode_1_Shift_5': '自动6档',
  'device.mode.Mode_2_Shift_0': '睡眠1档',

  'device.alert.offline': '离线',
  'device.alert.noPowerSupply': '电源断开',
  'device.alert.filterUsedUp': '滤芯告警',
  'device.alert.doorOpen': '舱门盖未关',
  'device.alert.filterElementPackageNotRemoved': '滤芯保护膜未拆',

  'device.command.status.offline': '设备离线',
  'device.command.status.jam': '指令被阻塞',
  'device.command.status.sent': '指令已发送',
  'device.command.status.received': '指令已被接收',
  'device.command.status.timeout': '指令超时',
  'device.command.status.set': '已设置',

  'device.function.restoreAfterHours': '小时后恢复智能场景/规则',
  'device.function.concurrency': '并发数',
  'device.function.ruleSet': '设置智能规则',
  'device.scene.apply_device_scene': '是否启用场景',
  'device.scene.device_scene_id': '选择智能场景',

  'isoCountryCode.CHN': '中华人民共和国',
  'isoCountryCode.MYS': 'Malaysia',

  'appclient.error.needLocationPermission': '需要定位权限，请重试',

  'app.copyright': '2020-2070 版权所有 柒贰零（北京）健康科技有限公司',
  'device.list.deviceSN': '设备SN',
  'device.list.spacePath': '空间路径',
  'device.list.category': '分类',
  'device.list.deviceType': '设备型号',
  'device.list.status': '运行状态',
  'device.list.alert': '异常告警',
  'device.list.filterLife': '滤芯剩余',
  'device.list.scene': '智能场景',
  'device.list.scenePause': '场景暂停',
  'device.list.deviceCount': '设备数：',
  'device.list.batchSetScene': '批量设置场景',
  'device.list.batchCommand': '批量立即指令',
  'filterLife.stats.now': '立即更换',
  'filterLife.stats.days30': '30天内更换',
  'filterLife.stats.days60': '60天内更换',
  'filterLife.stats.noNeed': '无需更换',
  'scene.stats.notSet': '空',
  'scene.stats.apply': '启用',
  'scene.stats.pause': '暂停',
  'scene.stats.stop': '停用',
  'device.locations': '设备分布（共{count}台）',
  'device.types': '设备型号统计',
  'device.alerts': '设备故障告警',
  'device.stats.status': '设备运行模式统计',
  'device.stats.X1000FilterLife': 'X1000滤芯剩余统计',
  'device.stats.X400FilterLife': 'X400滤芯剩余统计',
  'device.stats.scene': '设备智能场景统计',
  'device.list.onlyAllowSameType': '请只选择同一种型号的设备',
  'device.list.cannotAcrossOrg': '不能跨组织批量设置场景',
  'device.list.noChange': '不变',
  'device.list.on': '开',
  'device.list.off': '关',
  'device.list.unknown': '未知',
  'colorLightMode.0': '关',
  'colorLightMode.1': '自动',
  'colorLightMode.2': '其它',
  'colorLightMode.3': '蓝色',
  'colorLightMode.4': '紫色',
  'colorLightMode.5': '绿色',
  'colorLightMode.6': '黄色',
  'colorLightMode.7': '红色',
  'pm25.gauge.fine': '优',
  'pm25.gauge.good': '良',
  'pm25.gauge.poor': '差',
  'pm25.indoor': '室内PM2.5',
  'pm25.outdoor': '室外PM2.5',
  'temperature.indoor': '室内温度',
  'temperature.gauge.cold': '冷',
  'temperature.gauge.comfort': '舒适',
  'temperature.gauge.hot': '热',
  'humidity.indoor': '室内湿度',
  'humidity.gauge.dry': '干燥',
  'humidity.gauge.littleDry': '略干',
  'humidity.gauge.comfort': '舒适',
  'unit.hour': '小时',
  'label.workTime': '累计工作',
  'unit.purified': '米³',
  'label.purified': '累计消毒净化',
  'label.purifiedAvg': '平均消毒净化',
  'unit.purifiedAvg': '米³/台·小时',
  'label.powerCost': '累计耗电',
  'unit.powerCost': '度',
  'label.powerCostAvg': '平均耗电',
  'unit.powerCostAvg': '度/台·小时',
  'period.month': '月',
  'period.week': '周',
  'period.day': '日',
  'chart.purify': '净化数据',
  'chart.powerCost': '能耗运行数据',
  'dlg.noChange': '不改变',
  'dlg.noNeedToChange': '没有需要改变的设置',
  'dlg.noNeedToSendCommand': '没有指令需要发送',
  'dlg.notSetCommand': '没有设置任何指令',
  'dlg.restoreAfterHours.required': '请输入“小时后恢复智能场景/规则”',
  'dlg.concurrency.required': '请输入并发数',
  'dlg.prev': '上一步',
  'dlg.next': '下一步',
  'dlg.cancel': '取消',
  'dlg.finish': '完成',
  'dlg.table.seq': '顺序',
  'dlg.table.sn': '设备SN',
  'dlg.table.commandStatus': '指令发送状态',
  'dlg.table.count': '总数：{count}',
  'dlgDeviceScene.title': '设置设备的智能场景',
  'dlgDeviceScene.step1': '将要设置场景的设备列表',
  'dlgDeviceScene.step2': '设置场景',
  'dlgDeviceScene.step3': '场景设置过程',
  'dlgDeviceCommand.title': '向设备发送立即指令',
  'dlgDeviceCommand.step1': '将要发送指令的设备列表',
  'dlgDeviceCommand.step2': '配置将要发送的指令',
  'dlgDeviceCommand.step3': '指令发送过程',
  'device.location': '设备位置',
  'device.setScene': '设置场景',
  'device.sendCommand': '发送指令',
  'device.card.status': '设备运行状态',
  'device.card.alerts': '设备告警',
  'device.card.mode': '模式',
  'device.card.mode.manual': '手动',
  'device.card.mode.auto': '自动',
  'device.card.mode.sleep': '睡眠',
  'device.card.speed': '风速',
  'device.card.shift': '{shift}档',
  'device.card.basicInfo': '设备基本信息',
  'device.card.refresh': '刷新',
  'device.card.firmwareVersion': '固件版本',
  'device.card.remain': '剩余',
  'device.card.currentRuleset': '当前规则集',
  'device.card.noRuleset': '无',
  'ruleset.empty': '清空',
  'ruleset.custom': '自定义',
  'ruleset.moveUp': '上移',
  'ruleset.humanActivity': '附近人体活动',
  'ruleset.lightNearby': '附近环境明亮',
  'ruleset.powerOn': '开机',
  'ruleset.powerOff': '关机',
  'ruleset.modeSetTo': '设为{mode}',
  'ruleset.colorLightModeSetTo': '氛围灯设置为 {mode}',
  'app.yes': '是',
  'app.no': '否',
  'scene.apply_on_workday': '工作日',
  'scene.apply_on_holiday': '非工作日',
  'scene.apply_on_monday': '周一',
  'scene.apply_on_tuesday': '周二',
  'scene.apply_on_wednesday': '周三',
  'scene.apply_on_thursday': '周四',
  'scene.apply_on_friday': '周五',
  'scene.apply_on_saturday': '周六',
  'scene.apply_on_sunday': '周日',
  'scene.by_workday_dayoff': '按工作日/非工作日',
  'scene.by_weekday': '按周几',
  'scene.date_condition_required': '需要选择至少一种日期条件',
  'scene.command_ruleset_required': '没有设置任何指令或规则集',
  'scene.noChangeRuleset': '不改变 规则集',
  'scene.emptyRuleset': '清空 规则集',
  'scene.customRuleset': '自定义 规则集',
  'xuanwuApi.error.cannotDeleteNotEmptySpace': '无法删除非空的空间结点',
  'xuanwuApi.error.cannotDeleteNotEmptyOrg': '无法删除非空的组织结点',
  'opclient.xuanwuOperation': '玄武运维',
  'opclient.logout': '登出',
  'opclient.installPuriX': '安装X系列空气消毒机',
  'opclient.testPuriX': '测试X系列空气消毒机',
  'opclient.setupTvClient': '设置大屏显示',
  'opclient.accessDeny': '没有足够的权限',
  'opclient.notPuriX': '不是X系列消毒机的SN号',
  'opclient.setupWifiFinished': '已完成绑定和配网',
  'opclient.setupWifiCancelled': '已完成绑定，配网被取消',
  'opclient.clickToSelectSpace': '请点击选择空间',
  'opclient.bindSpace': '关联的空间',
  'opclient.deviceSN': '设备SN号',
  'opclient.clickToBind': '请点击进行扫码和定位',
  'opclient.bindFinish': '已完成绑定',
  'opclient.bindDevice': '绑定设备',
  'opclient.andSetupWifi': '并配网',
  'opclient.notQrCodeForTvSetup': '不是大屏显示的设置二维码',
  'opclient.onlySpaceNodeAllowed': '只能选择空间结点',
  'opclient.cannotBindToSpaceNodeWithChildren': '不能绑定到有子空间的结点',
  'opclient.selectSpace': '选择空间',
  'opclient.sendCommand': '发送{deviceTypeShort}指令',
  'opclient.clickToScan': '请点击进行扫码',
  'opclient.powerSupply': '有电源',
  'opclient.lastManualCmd': '手动指令',
  'opclient.lastManualTime': '手动时间',
  'opclient.onlineCause': '上线原因',
  'opclient.macAddress': 'MAC地址',
  'opclient.ipAddress': 'IP地址',
  'opclient.oidVersion': 'oid版本',
  'opclient.totalAbsorbed': '累计吸附',
  'opclient.debugInfo': '调试信息',
  'opclient.filterElementPackage': '滤芯包装袋',
  'opclient.notRemoved': '未拆除',
  'opclient.removed': '已拆除',
  'opclient.genuineFilterElement': '原版滤芯',
  'opclient.notDetected': '未检测到',
  'opclient.detected': '检测到',
  'opclient.displayContent.required': '请选择显示内容！',
  'opclient.selectDisplayContent': '选择显示内容',
  'opclient.orgNode.required': '请选择所属组织！',
  'opclient.onlyAllowOrgNodeWithoutChildren': '只能选择末端组织',
  'opclient.selectOrgNode': '选择所属组织',
  'opclient.displaySettings': '大屏显示设置',
  'opclient.orgNode': '所属组织',
  'opclient.displayName': '请输入大屏名称',
  'opclient.selectOrgNodeFirst': '请先选择所属组织',
  'opclient.success': '操作成功',
  'opclient.deleted': '已删除',


  'config.tv-client.displayStyle.DebugFullScreen': '调试全屏',
  'config.tv-client.displayStyle.DebugTop': '调试屏幕顶部',
  'config.tv-client.displayStyle.DebugBottom': '调试屏幕底部',
  'config.tv-client.displayLocale': '本地化',
  'config.tv-client.displayLocale.require': '请选择本地化！',
  'opclient.selectDisplayLocale': '选择本地化',

  'locale.zh-CN': '简体中文',
  'locale.en-US': '英文',
  'locale.zh-TW': '繁体中文',

  'device.list.manualInterruptRestoreAfterHours': '按键中断时长',
  'device.dlg.manualInterrupt': '按键',
};
